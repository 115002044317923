import React, { useEffect, useState } from "react";
import { Table, message, Input, Button, Select, Breadcrumb } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Orders.css";
import { Link } from "react-router-dom";
import Locationicon from "../../images/VendorOrders/Locationicon.svg";
import Main from "./Layout";

const { Option } = Select;

const AdminOrders = () => {
	const { apiurl, token, currentCountry } = useAuth();
	const [orders, setOrders] = useState([]);
	const [filteredOrders, setFilteredOrders] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedYears, setSelectedYears] = useState([]);
	const [selectedStatuses, setSelectedStatuses] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);

	const statuses = ["confirmed", "shipped", "delivered", "processing"];

	useEffect(() => {
		fetchOrders();
	}, []);

	const fetchOrders = async () => {
		try {
			const response = await fetch(`${apiurl}/admin/orders`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			if (!response.ok) {
				message.error(data.error);
			} else {
				setOrders(data || []);
				setFilteredOrders(data || []);
			}
		} catch (error) {
			message.error("Failed to fetch vendor orders");
		} finally {
			setLoading(false);
		}
	};

	const applyFilters = () => {
		let filtered = [...orders];
		if (searchQuery) {
			filtered = filtered.filter((order) =>
				order.order_items.some((item) =>
					item.product_varient.product_name
						?.toLowerCase()
						.includes(searchQuery.toLowerCase())
				)
			);
		}
		if (selectedYears.length > 0) {
			filtered = filtered.filter((order) =>
				selectedYears.includes(new Date(order.order.created_at).getFullYear())
			);
		}
		if (selectedStatuses.length > 0) {
			filtered = filtered.filter((order) =>
				selectedStatuses.includes(order.order.status)
			);
		}
		setFilteredOrders(filtered);
	};

	useEffect(() => {
		applyFilters();
	}, [searchQuery, selectedYears, selectedStatuses, orders]);

	const columns = [
		{
			title: "Order ID",
			dataIndex: "id",
			key: "id",
		},

		{
			title: "Product",
			key: "product",
			render: (_, record) => {
				const { product_varient } = record.firstitem || {};
				const { product_name, color, images } = product_varient || {};
				const imageUrl = images?.[0]?.image || "https://via.placeholder.com/50";

				return (
					<div style={{ display: "flex", alignItems: "center" }}>
						<img
							src={
								imageUrl.startsWith("http") ? imageUrl : `${apiurl}${imageUrl}`
							}
							alt={product_name || "Product"}
							style={{
								width: "50px",
								height: "50px",
								borderRadius: "8px",
								objectFit: "contain",
								marginRight: "10px",
							}}
						/>
						<div>
							<div style={{ fontWeight: "bold" }}>
								{product_name?.substring(0, 15) || "N/A"}
							</div>
							<div>Color: {color?.name || "N/A"}</div>
						</div>
					</div>
				);
			},
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			render: (date) =>
				new Date(date).toLocaleDateString("en-US", {
					year: "numeric",
					month: "short",
					day: "numeric",
				}),
		},
		{
			title: "User",
			dataIndex: "user",
			key: "user",
		},

		{
			title: "Total Amount",
			dataIndex: "total_amount",
			key: "total_amount",
			render: (amount) => `${currentCountry.currency} ${amount}`,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Payment Method",
			dataIndex: "payment_method",
			key: "payment_method",
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Button
					type="text"
					icon={<EyeOutlined />}
					onClick={() => handleViewOrder(record)}
				/>
			),
		},
	];

	const itemcolumns = [
		{
			title: "Product",
			key: "product",
			render: (_, record) => {
				const { product_varient } = record || {};
				const { product_name, color, images } = product_varient || {};
				const imageUrl =
					images?.[0]?.image ||
					"https://images.firstpost.com/uploads/2024/02/MS-Dhoni-CSK-IPL-2023-PTI-1200-2024-02-9c8c7d515e57c707fd33cef140b5d687.jpg?im=FitAndFill=(596,336)";

				return (
					<div style={{ display: "flex", alignItems: "center" }}>
						<img
							src={`${apiurl}${imageUrl}`}
							alt={product_name || "Product"}
							style={{
								width: 50,
								height: 50,
								objectFit: "cover",
								marginRight: 10,
							}}
						/>
						{}
						<div>
							<div style={{ fontWeight: "bold" }}>{product_name || "N/A"}</div>
							<div>Color: {color?.name || "N/A"}</div>
						</div>
					</div>
				);
			},
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			render: (price) => `₹${price}`,
		},
		{
			title: "Product Vendor",
			dataIndex: "product_vendor",
			key: "product_vendor",
			render: (_, record) => {
				const { product_varient } = record || {};
				return product_varient?.product_vendor || "Vendor Not Available";
			},
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];

	const handleViewOrder = (order) => {
		setSelectedOrder(order);
	};

	const handleBackToOrders = () => {
		setSelectedOrder(null);
	};
	const handleInvoice = () => {
		message.success("download invoice");
	};

	return (
		<Main>
			{selectedOrder ? (
				<div className="vendor-specific-order-container">
					<h2 className="vendor-specific-order-header">
						Order: #{selectedOrder.id}
					</h2>
					<div className="vendor-specific-order-bread-crumb-invoice">
						<Breadcrumb className="vendor-specific-order-breadcrumb">
							<Breadcrumb.Item>
								<Link
									type="primary"
									to={"/admin/orders"}
									onClick={handleBackToOrders}
									className="vendor-specific-order-link">
									Orders
								</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item>Order Details</Breadcrumb.Item>
						</Breadcrumb>

						<Button
							type="primary"
							className="vendor-specific-order-invoice"
							onClick={handleInvoice}>
							Invoice
						</Button>
					</div>

					<div className="vendor-specific-order-products-generalinfo-container">
						<div className="vendor-specific-order-products-address">
							<div className="vendor-specific-order-products-table">
								<Table
									dataSource={selectedOrder?.allitems}
									columns={itemcolumns}
									rowKey={(record) => record.id}
									pagination={false}
									footer={() => {
										return (
											<div className="vendor-specific-order-products-table-footer">
												<p>
													<span>Total:</span> {currentCountry.currency} {" "}
													{selectedOrder?.total_amount}
												</p>
											</div>
										);
									}}
								/>
							</div>

							<div className="vendor-specific-order-address-billing">
								<div className="vendor-specific-order-address-card">
									<div className="vendor-specific-order-address">
										<div className="vendor-specific-order-locationlogo-heading">
											<img src={Locationicon} alt="location" />
											<h2>Shipping Address</h2>
										</div>
										<p>
											{selectedOrder?.order?.shipping_address?.contact_name},
											{selectedOrder?.order?.shipping_address?.contact_number},
											<br />
											{selectedOrder?.order?.shipping_address?.address},
											{selectedOrder?.order?.shipping_address?.city},
											{selectedOrder?.order?.shipping_address?.country},
											<br />
											{selectedOrder?.order?.shipping_address?.pincode},
											{selectedOrder?.order?.shipping_address?.state}
										</p>
									</div>
								</div>
								<Button
									type="primary"
									className="vendor-specific-order-back-to-orders"
									onClick={handleBackToOrders}>
									Back to Orders
								</Button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="admin-orders-main">
					<div className="filters-group">
						<Input
							placeholder="Search by Product Name"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<Select
							mode="multiple"
							placeholder="Filter by Year"
							onChange={setSelectedYears}
							allowClear>
							{Array.from(
								new Set(
									orders.map((order) =>
										new Date(order.order.created_at).getFullYear()
									)
								)
							).map((year) => (
								<Option key={year} value={year}>
									{year}
								</Option>
							))}
						</Select>
						<Select
							mode="multiple"
							placeholder="Filter by Status"
							onChange={setSelectedStatuses}
							allowClear>
							{statuses.map((status) => (
								<Option key={status} value={status}>
									{status}
								</Option>
							))}
						</Select>
					</div>
					<Table
						columns={columns}
						dataSource={filteredOrders?.map((order) => ({
							key: order?.order?.id,
							id: order?.order?.id,
							order: order?.order,
							allitems: order?.order_items,
							firstitem: order?.order_items?.[0],
							status: order?.order?.status,
							payment_method: order?.order?.payment_method,
							total_amount: order?.order?.total_amount,
							user: order?.order?.user,
							created_at: order?.order?.created_at,
						}))}
						loading={loading}
						pagination={{ pageSize: 10 }}
					/>
				</div>
			)}
		</Main>
	);
};

export default AdminOrders;
