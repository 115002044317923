import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import { message, Modal, Radio, Button, Spin, Tag, Steps } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Main from "./Layout";
import AddAddressForm from "./AddAddressForm";
import "./Cart.css";
import "./cart-responsive.css";
import { useNavigate } from "react-router-dom";
import EmptyCart from "./EmptyCart";

const { Step } = Steps;

const Cart = () => {
	const [cart, setCart] = useState([]);
	const [loading, setLoading] = useState(false);
	const { apiurl, token, currentCountry } = useAuth();
	const [address, setAddress] = useState([]);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [addressModalVisible, setAddressModalVisible] = useState(false);
	const [addAddressModalVisible, setAddAddressModalVisible] = useState(false);
	const navigate = useNavigate();

	const shippingCharges = 50;

	const shippingOptions = [
		{
			id: 1,
			name: "Standard Shipping",
			price: 50,
			deliveryDuration: "10 - 15 days",
		},
		{
			id: 2,
			name: "Express Shipping",
			price: 100,
			deliveryDuration: "2 - 3 days",
		},
	];

	const paymentMethods = [
		{
			id: 1,
			name: "COD",
		},
		{
			id: 2,
			name: "Payment Gateway",
		},
	];

	const [selectedShipping, setSelectedShipping] = useState(shippingOptions[0]);
	const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);

	const [currentStep, setCurrentStep] = useState(0);

	const fetchCart = async () => {
		try {
			const response = await fetch(`${apiurl}/cart/`, {
				method: "GET",
				headers: { Authorization: "Bearer " + token },
			});
			if (response.ok) {
				const data = await response.json();
				setCart(data.items);
			} else {
				message.error("Error fetching cart");
			}
		} catch {
			message.error("Please check your network and try again");
		}
	};

	useEffect(() => {
		fetchCart();
		fetchAddress();
	}, []);

	const getCountrySpecificPrice = (productVariant) => {
		const countryPrice = productVariant.country_specific_prices.find(
			(price) => price.country_name === currentCountry.name
		);
		return countryPrice
			? parseFloat(countryPrice.price)
			: productVariant.discounted_price;
	};

	const getCountrySpecificDiscPrice = (productVariant) => {
		const price = getCountrySpecificPrice(productVariant);
		const countryDiscountType = productVariant.country_specific_prices.find(
			(priceInfo) => priceInfo.country_name === currentCountry.name
		)?.discount_type;
		const countryDiscount = productVariant.country_specific_prices.find(
			(priceInfo) => priceInfo.country_name === currentCountry.name
		)?.discount;

		let discountPrice = 0;
		if (countryDiscountType === "Amount") {
			discountPrice = parseFloat(price) - parseFloat(countryDiscount);
		} else {
			discountPrice =
				parseFloat(price) -
				(parseFloat(price) * parseFloat(countryDiscount)) / 100;
		}
		if (!discountPrice) {
			return price;
		}
		return discountPrice;
	};

	const handleDelete = (itemId) => {
		Modal.confirm({
			title: "Are you sure you want to delete this item?",
			onOk: async () => {
				try {
					const response = await fetch(`${apiurl}/cart/`, {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + token,
						},
						body: JSON.stringify({
							cart_item_id: itemId,
						}),
					});

					if (response.ok) {
						fetchCart();
						message.success("Item deleted successfully");
					} else {
						message.error("Failed to delete item");
					}
				} catch (error) {
					message.error("An error occurred while deleting the item");
				}
			},
		});
	};

	const handleQuantityChange = async (type, item) => {
		setLoading(true);
		let quantity = item.quantity;
		if (type === "increased") {
			quantity = item.quantity + 1;
		} else {
			quantity = item.quantity - 1;
		}
		const response = await fetch(`${apiurl}/cart/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				item_id: item.product_varient.id,
				quantity: quantity,
			}),
		});
		if (response.ok) {
			fetchCart();
		}
		setLoading(false);
	};

	const fetchAddress = async () => {
		const response = await fetch(`${apiurl}/address/`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		if (response.ok) {
			const data = await response.json();
			setAddress(data.data);
			setSelectedAddress(data.data[0] || null);
		} else {
			message.error("Failed to fetch address");
		}
	};

	const handleAddressChange = (e) => {
		setSelectedAddress(address.find((addr) => addr.id === e.target.value));
	};

	const handleShippingTypeChange = (e) => {
		setSelectedShipping(
			shippingOptions.find((addr) => addr.id === e.target.value)
		);
	};

	const handlePaymethodChange = (e) => {
		setPaymentMethod(paymentMethods.find((addr) => addr.id === e.target.value));
	};

	const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
	const totalAmount = cart.reduce(
		(acc, item) =>
			acc + getCountrySpecificPrice(item.product_varient) * item.quantity,
		0
	);
	const totalDisAmount = cart.reduce(
		(acc, item) =>
			acc + getCountrySpecificDiscPrice(item.product_varient) * item.quantity,
		0
	);

	const discount = totalAmount - totalDisAmount;

	const netPay = totalAmount + shippingCharges - discount;

	const handlePlaceOrder = async () => {
		setLoading(true);

		const tax = 0.0;
		const items = cart.map((item) => ({
			prod_varient: item.product_varient.id,
			quantity: item.quantity,
			price: getCountrySpecificDiscPrice(item.product_varient),
		}));

		const payload = {
			total_amount: totalAmount,
			payment_method: paymentMethod.name,
			shipping_charges: shippingCharges,
			tax,
			discount,
			shipping_address: selectedAddress.id,
			items,
		};

		try {
			const response = await fetch(`${apiurl}/orders/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payload),
			});

			const data = await response.json();

			if (response.ok) {
				message.success(
					`Order placed successfully! Order ID: ${data.order_id}`
				);
				navigate("/ordersuccess");
			} else {
				message.error(`Error placing order: ${data.error || "Unknown error"}`);
			}
		} catch (error) {
			message.error(
				"Something went wrong while placing the order. Please try again."
			);
		} finally {
			setLoading(false);
		}
	};

	const steps = [
		{
			title: "Cart",
			content: (
				<div className="cart-container">
					<div className="products-list">
						<div className="address-section">
							{selectedAddress ? (
								<div className="address-details">
									<div className="name-section">
										<p className="name">
											Deliver To: {selectedAddress.contact_name},{" "}
											{selectedAddress.pincode}
										</p>
										<Button
											onClick={() => setAddressModalVisible(true)}
											type="link"
											className="change-address-btn">
											Change Address
										</Button>
									</div>
									<p className="address">
										{selectedAddress.address}, {selectedAddress.city},{" "}
										{selectedAddress.country}
									</p>
								</div>
							) : (
								<Button
									onClick={() => setAddAddressModalVisible(true)}
									type="primary"
									className="add-address-btn">
									Add Address
								</Button>
							)}
						</div>

						{cart.length > 0 ? (
							cart.map((item) => {
								const product = item.product_varient;
								const price = getCountrySpecificPrice(product);
								const discprice = getCountrySpecificDiscPrice(product);

								return (
									<div key={item.id} className="cart-item">
										<div className="cart-image">
											<img
												src={apiurl + product.images[0]?.image}
												alt={product.product_name}
											/>
										</div>
										<div className="cart-details">
											<h3>
												{product.product_name}
												{product.product_name}
											</h3>
											<p className="cart-price">
												{price === discprice ? (
													<span className="price">
														{currentCountry.currency} {price}
													</span>
												) : (
													<>
														<span className="total-price original-price">
															{currentCountry.currency} {price}
														</span>
														<span className="discounted-price">
															{currentCountry.currency} {discprice}
														</span>
													</>
												)}{" "}
											</p>
											<p>
												<strong>Color:</strong> {product.color.name} /{" "}
												{product.size}
											</p>
											<div className="cart-quantity">
												<button
													className="quantity-btn"
													onClick={() =>
														handleQuantityChange("decreased", item)
													}
													disabled={loading}>
													{loading ? <Spin size="small" /> : "-"}
												</button>
												<span>{item.quantity}</span>
												<button
													className="quantity-btn"
													onClick={() =>
														handleQuantityChange("increased", item)
													}
													disabled={loading}>
													{loading ? <Spin size="small" /> : "+"}
												</button>
											</div>
										</div>
										<div className="cart-delete">
											<button
												onClick={() => handleDelete(item.id)}
												className="delete-btn">
												<DeleteOutlined />
											</button>
										</div>
									</div>
								);
							})
						) : (
							<p>Your cart is empty.</p>
						)}
					</div>
					<div className="order-summary">
						<h3>Order Summary</h3>
						<div className="summary-item">
							<p>
								Total Items: <span>{totalItems}</span>
							</p>
						</div>
						<div className="summary-item">
							<p>
								Total Amount:{" "}
								<span>
									{currentCountry.currency} {totalAmount}
								</span>
							</p>
						</div>
						{discount > 0 && (
							<div className="summary-item">
								<p>
									Discount:{" "}
									<span>
										- {currentCountry.currency} {discount}
									</span>
								</p>
							</div>
						)}
						<div className="summary-item">
							<p>
								Shipping Charges:{" "}
								<span>
									{currentCountry.currency} {shippingCharges}
								</span>
							</p>
						</div>
						<div className="summary-item">
							<h4>
								Net Pay: {currentCountry.currency} {netPay}
							</h4>
						</div>
					</div>
				</div>
			),
		},
		{
			title: "Address",
			content: (
				<div className="address-container">
					<div className="address-section">
						<div className="address-details">
							<div className="name-section">
								<p className="name">Delivery Address</p>
							</div>
							<Radio.Group
								onChange={handleAddressChange}
								value={selectedAddress?.id}>
								{address.length > 0 &&
									address.map((addr) => (
										<Radio
											key={addr.id}
											value={addr.id}
											className="address-radio">
											<div className="address-details">
												<div className="address-header">
													<span className="name">{addr.contact_name}</span>
													<Tag color="#5f5f5f">{addr.address_type}</Tag>
												</div>
												<p className="address">
													{addr.address}, {addr.city}, {addr.country} -{" "}
													{addr.pincode}
												</p>
												<p className="mobile">Mobile: {addr.contact_number}</p>
												<div className="address-actions">
													<Button type="default" className="edit-btn">
														Edit
													</Button>
													<Button type="default" className="delete-btn">
														Delete
													</Button>
												</div>
											</div>
										</Radio>
									))}
							</Radio.Group>
							<div className="add-new-adr-btn">
								<Button
									style={{ marginTop: 16 }}
									onClick={() => setAddAddressModalVisible(true)}>
									Add New Address
								</Button>
							</div>
						</div>
					</div>
					<div className="order-summary">
						<h1>Shopping Bag ({totalItems})</h1>
						{cart.length > 0 &&
							cart.map((item) => {
								const product = item.product_varient;
								const price = getCountrySpecificPrice(product);

								return (
									<div key={item.id} className="cart-item">
										<div className="cart-image">
											<img
												src={apiurl + product.images[0]?.image}
												alt={product.product_name}
											/>
										</div>
										<div className="cart-details">
											<h3>{product.product_name}</h3>
											<p className="cart-price">
												{currentCountry.currency} {price}{" "}
												<span>x {item.quantity}</span>
											</p>
											<p>
												<strong>Varient:</strong> {product.color.name} /{" "}
												{product.size}
											</p>
										</div>
									</div>
								);
							})}
						<h3>Order Summary</h3>
						<div className="summary-item">
							<p>
								Total Items: <span>{totalItems}</span>
							</p>
						</div>
						<div className="summary-item">
							<p>
								Total Amount:{" "}
								<span>
									{currentCountry.currency} {totalAmount}
								</span>
							</p>
						</div>
						{discount > 0 && (
							<div className="summary-item">
								<p>
									Discount:{" "}
									<span>
										- {currentCountry.currency}
										{discount}
									</span>
								</p>
							</div>
						)}
						<div className="summary-item">
							<p>
								Shipping Charges:{" "}
								<span>
									{currentCountry.currency} {shippingCharges}
								</span>
							</p>
						</div>
						<div className="summary-item">
							<h4>
								Net Pay: {currentCountry.currency} {netPay}
							</h4>
						</div>
					</div>
				</div>
			),
		},
		{
			title: "Shipping",
			content: (
				<div className="shipping-container">
					<div className="address-section">
						{selectedAddress && (
							<div className="address-details">
								<div className="name-section">
									<p className="name">
										Deliver To: {selectedAddress.contact_name},{" "}
										{selectedAddress.pincode}
									</p>
								</div>
								<p className="address">
									{selectedAddress.address}, {selectedAddress.city},{" "}
									{selectedAddress.country}
								</p>
							</div>
						)}

						<Radio.Group
							onChange={handleShippingTypeChange}
							value={selectedShipping.id}>
							{shippingOptions.length > 0 &&
								shippingOptions.map((addr) => (
									<Radio
										key={addr.id}
										value={addr.id}
										className="address-radio">
										<div className="shipping-details">
											<div className="address-header">
												<span className="name">{addr.name}</span>
												<h3>
													{currentCountry.currency} {addr.price}
												</h3>
											</div>
											<p className="mobile">
												Estimated Delivery: {addr.deliveryDuration}
											</p>
										</div>
									</Radio>
								))}
						</Radio.Group>
					</div>
					<div className="order-summary">
						<h1>Shopping Bag ({totalItems})</h1>
						{cart.length > 0 &&
							cart.map((item) => {
								const product = item.product_varient;
								const price = getCountrySpecificPrice(product);

								return (
									<div key={item.id} className="cart-item">
										<div className="cart-image">
											<img
												src={apiurl + product.images[0]?.image}
												alt={product.product_name}
											/>
										</div>
										<div className="cart-details">
											<h3>{product.product_name}</h3>
											<p className="cart-price">
												{currentCountry.currency} {price}{" "}
												<span>x {item.quantity}</span>
											</p>
											<p>
												<strong>Varient:</strong> {product.color.name} /{" "}
												{product.size}
											</p>
										</div>
									</div>
								);
							})}
						<h3>Order Summary</h3>
						<div className="summary-item">
							<p>
								Total Items: <span>{totalItems}</span>
							</p>
						</div>
						<div className="summary-item">
							<p>
								Total Amount:{" "}
								<span>
									{currentCountry.currency} {totalAmount}
								</span>
							</p>
						</div>
						{discount > 0 && (
							<div className="summary-item">
								<p>
									Discount:{" "}
									<span>
										- {currentCountry.currency} {discount}
									</span>
								</p>
							</div>
						)}
						<div className="summary-item">
							<p>
								Shipping Mode: <span>{selectedShipping.name}</span>
							</p>
						</div>
						<div className="summary-item">
							<p>
								Shipping Charges:{" "}
								<span>
									{currentCountry.currency} {selectedShipping.price}
								</span>
							</p>
						</div>
						<div className="summary-item">
							<h4>
								Net Pay: {currentCountry.currency}{" "}
								{totalAmount + selectedShipping.price - discount}
							</h4>
						</div>
					</div>
				</div>
			),
		},
		{
			title: "Payment",
			content: (
				<div className="payment-container">
					<div className="payment-summary">
						{selectedAddress && (
							<div className="address-details">
								<div className="name-section">
									<p className="name">
										Deliver To: {selectedAddress.contact_name},{" "}
										{selectedAddress.pincode}
									</p>
								</div>
								<p className="address">
									{selectedAddress.address}, {selectedAddress.city},{" "}
									{selectedAddress.country}
								</p>
							</div>
						)}
						<div className="payment-details">
							<h3>Payment Method</h3>
							<Radio.Group
								onChange={handlePaymethodChange}
								value={paymentMethod.id}>
								{paymentMethods.length > 0 &&
									paymentMethods.map((addr) => (
										<Radio
											key={addr.id}
											value={addr.id}
											className="address-radio">
											<div className="shipping-details">
												<div className="address-header">
													<span className="name">{addr.name}</span>
												</div>
											</div>
										</Radio>
									))}
							</Radio.Group>
							<Button
								type="primary"
								onClick={handlePlaceOrder}
								className="pay-btn">
								Pay Amount
							</Button>
						</div>
					</div>
					<div className="order-summary">
						<h1>Shopping Bag ({totalItems})</h1>
						{cart.length > 0 &&
							cart.map((item) => {
								const product = item.product_varient;
								const price = getCountrySpecificPrice(product);

								return (
									<div key={item.id} className="cart-item">
										<div className="cart-image">
											<img
												src={apiurl + product.images[0]?.image}
												alt={product.product_name}
											/>
										</div>
										<div className="cart-details">
											<h3>{product.product_name}</h3>
											<p className="cart-price">
												{currentCountry.currency} {price}{" "}
												<span>x {item.quantity}</span>
											</p>
											<p>
												<strong>Varient:</strong> {product.color.name} /{" "}
												{product.size}
											</p>
										</div>
									</div>
								);
							})}
						<h3>Order Summary</h3>
						<div className="summary-item">
							<p>
								Total Items: <span>{totalItems}</span>
							</p>
						</div>
						<div className="summary-item">
							<p>
								Total Amount:{" "}
								<span>
									{currentCountry.currency} {totalAmount}
								</span>
							</p>
						</div>
						{discount > 0 && (
							<div className="summary-item">
								<p>
									Discount:{" "}
									<span>
										- {currentCountry.currency} {discount}
									</span>
								</p>
							</div>
						)}
						<div className="summary-item">
							<p>
								Shipping Mode: <span>{selectedShipping.name}</span>
							</p>
						</div>
						<div className="summary-item">
							<p>
								Shipping Charges:{" "}
								<span>
									{currentCountry.currency} {selectedShipping.price}
								</span>
							</p>
						</div>
						<div className="summary-item">
							<h4>
								Net Pay: {currentCountry.currency}{" "}
								{totalAmount + selectedShipping.price - discount}
							</h4>
						</div>
					</div>
				</div>
			),
		},
	];

	const handleNext = () => {
		setCurrentStep(currentStep + 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const handlePrevious = () => {
		setCurrentStep(currentStep - 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	if (cart.length <= 0) {
		return <EmptyCart />;
	}

	return (
		<Main>
			<div className="cart-main">
				<Steps current={currentStep} className="cart-steps-container">
					{steps.map((step, index) => (
						<Step key={index} title={step.title} />
					))}
				</Steps>

				<div className="steps-content">{steps[currentStep].content}</div>

				<div className="steps-action">
					{currentStep > 0 && (
						<Button style={{ margin: "0 8px" }} onClick={handlePrevious}>
							Previous
						</Button>
					)}
					{currentStep < steps.length - 1 && (
						<Button type="primary" onClick={handleNext}>
							Next
						</Button>
					)}
				</div>

				<div className="cartmodals">
					<Modal
						className="change-address-modal"
						title="Select a Shipping Address"
						open={addressModalVisible}
						onCancel={() => setAddressModalVisible(false)}
						footer={""}>
						<Radio.Group
							onChange={handleAddressChange}
							value={selectedAddress?.id}>
							{address.length > 0 &&
								address.map((addr) => (
									<Radio
										key={addr.id}
										value={addr.id}
										className="address-radio">
										<div className="address-details">
											<div className="address-header">
												<span className="name">{addr.contact_name}</span>
												<Tag color="#5f5f5f">{addr.address_type}</Tag>
											</div>
											<p className="address">
												{addr.address}, {addr.city}, {addr.country} -{" "}
												{addr.pincode}
											</p>
											<p className="mobile">Mobile: {addr.contact_number}</p>
											<div className="address-actions">
												<Button type="default" className="edit-btn">
													Edit
												</Button>
												<Button type="default" className="delete-btn">
													Delete
												</Button>
											</div>
										</div>
									</Radio>
								))}
						</Radio.Group>
						<div className="add-new-adr-btn">
							<Button
								style={{ marginTop: 16 }}
								onClick={() => setAddAddressModalVisible(true)}>
								Add New Address
							</Button>
						</div>
					</Modal>

					<Modal
						title="Add a New Address"
						open={addAddressModalVisible}
						onCancel={() => {
							setAddAddressModalVisible(false);
							fetchAddress();
						}}
						footer={null}>
						<AddAddressForm />
					</Modal>
				</div>
			</div>
		</Main>
	);
};

export default Cart;
