import React, { useState } from "react";
import { Input, Button, message, Tabs } from "antd";
import { useAuth } from "../utils/useAuth";
import { Link, useNavigate } from "react-router-dom";
import "./auth.css";
import background from "./../../images/Login/background.png";


const VendorLoginForm = () => {
	const { apiurl, handleLogin } = useAuth();
	const [loading, setLoading] = useState(false);
	
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};


	const verifyOTP = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/login/vendor/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email:formData.email,
					password: formData.password,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Login successfull!");
				handleLogin(data.access_token, data.role, data.countryCode);
				navigate("/");
			} else {
				message.error(data.error || "Invalid credentials");
			}
		} catch (error) {
			message.error("An error occurred while verifying OTP");
		} finally {
			setLoading(false);
		}
	};


	return (
		<div className="login-main">
			<img src={background} alt="sheEagle"></img>
			<div className="login-left">
				<button className="close-btn" onClick={() => navigate("/")}>
					X
				</button>
				<div className="tabs-content">
					<h2>Existing Member</h2>
					<p>Welcome Back!</p>
					<div className="vendor-lg-cont">
						<label htmlFor="email">Email</label>
						<Input
							id="email"
							// placeholder="Enter your email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							style={{ marginBottom: 8 }}
						/>
						<>
							<label htmlFor="otp-email">Password</label>
							<Input.Password
								id="otp-email"
								// placeholder="Enter OTP"
								name="password"
								value={formData.password}
								onChange={handleInputChange}
								style={{ marginBottom: 8 }}
							/>

							<Button
								type="primary"
								loading={loading}
								onClick={verifyOTP}
								style={{ width: "100%" }}>
								Login
							</Button>
						</>
					</div>
				</div>

				<div className="sign-up-text">
					<p>
						Didn't Have a Seller Account. <Link to="/vendor/signup">Create New</Link>
					</p>
					<p>
						Login as a buyer. <Link to="/login">Login</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default VendorLoginForm;
