import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Input, Card, Pagination, Spin } from "antd";
import "./Users.css";
import Main from "./Layout";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const Users = () => {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const usersPerPage = 15;
	const { apiurl, token, currentCountry } = useAuth();
	const navigate = useNavigate();

	const getUsers = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/admin/getusers/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setUsers(data);
				setFilteredUsers(data);
			}
		} catch (error) {
			console.error("Error fetching users:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleSearch = (value) => {
		const filtered = users.filter((user) =>
			user.user.username.toLowerCase().includes(value.toLowerCase())
		);
		setFilteredUsers(filtered);
		setCurrentPage(1);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		getUsers();
	}, []);

	const indexOfLastUser = currentPage * usersPerPage;
	const indexOfFirstUser = indexOfLastUser - usersPerPage;
	const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

	return (
		<Main>
			<div className="user-list-container">
				<Search
					placeholder="Search Customer..."
					onSearch={handleSearch}
					className="search-input"
					enterButton
				/>
				{loading ? (
					<div className="loading-container">
						<Spin size="large" />
					</div>
				) : (
					<>
						<div className="user-grid">
							{currentUsers.map((user, index) => (
								<Card
									key={index}
									className="user-card"
									onClick={() => navigate("/admin/users/" + user.user.id)}>
									<div className="avatar">
										{user.user.username?.charAt(0).toUpperCase() || "?"}
									</div>
									<h3 className="user-name">{user.user.username}</h3>
									<div className="card-ft">
										<p className="user-details">
											Orders
											<br /> {user.orders || 0}
										</p>
										<p className="user-details">
											Balance
											<br /> {currentCountry.currency} {" "}
											{user.user.credit?.toLocaleString() || "0.00"}
										</p>
									</div>
								</Card>
							))}
						</div>
						<div className="pagination-container">
							<Pagination
								current={currentPage}
								pageSize={usersPerPage}
								total={filteredUsers.length}
								onChange={handlePageChange}
								showSizeChanger={false}
							/>
						</div>
					</>
				)}
			</div>
		</Main>
	);
};

export default Users;
