import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
	HomeOutlined,
	DashboardOutlined,
	ShoppingCartOutlined,
	ReconciliationOutlined,
	PlusCircleOutlined,
	TagOutlined,
	DollarOutlined,
	BellOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, message, Modal } from "antd";
import "./Layout.css";
import logo from "./../../images/Homepage/logo.svg";
import { useAuth } from "../utils/useAuth";
import Notifications from "../Home/Notifications";

const { Header, Sider } = Layout;

const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { apiurl, token, handleLogout } = useAuth();
	const [user, setUser] = useState();
	const [date, setDate] = useState("");
	const [showNotifications, setShowNotifications] = useState(false);

	const notificationsRef = useRef(null);
	const bellIconRef = useRef(null);

	const handleLogoutClick = () => {
		Modal.confirm({
			title: "Confirm Logout",
			content: "Are you sure you want to log out?",
			okText: "Yes",
			cancelText: "No",
			onOk: () => {
				handleLogout();
				navigate("/login");
			},
		});
	};

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;
		const menuItems = [
			"/vendor/home",
			"/vendor/categories",
			"/vendor/products",
			"/vendor/addproduct",
			"/vendor/orders",
			"/vendor/returns",
			"/vendor/coupons",
			"/vendor/account",
		];

		const index = menuItems.findIndex((item) => pathname === item);
		return index !== -1 ? [`${index + 1}`] : ["1"];
	};

	const menuItems = [
		{
			key: "1",
			icon: <HomeOutlined />,
			label: <Link to="/vendor/home">Home</Link>,
		},
		{
			key: "2",
			icon: <DashboardOutlined />,
			label: <Link to="/vendor/categories">Categories</Link>,
		},
		{
			key: "3",
			icon: <ShoppingCartOutlined />,
			label: <Link to="/vendor/products">Products</Link>,
		},
		{
			key: "4",
			icon: <PlusCircleOutlined />,
			label: <Link to="/vendor/addproduct">Add Product</Link>,
		},
		{
			key: "5",
			icon: <ShoppingCartOutlined />,
			label: <Link to="/vendor/orders">Orders</Link>,
		},
		{
			key: "6",
			icon: <ReconciliationOutlined />,
			label: <Link to="/vendor/returns">Returns</Link>,
		},
		{
			key: "7",
			icon: <TagOutlined />,
			label: <Link to="/vendor/coupons">Coupons</Link>,
		},
		{
			key: "8",
			icon: <DollarOutlined />,
			label: <Link to="/vendor/account">Account</Link>,
		},
		{
			key: "9",
			icon: <LogoutOutlined />,
			label: <div onClick={handleLogoutClick}>Log Out</div>,
		},
	];

	useEffect(() => {
		getuserDetails();
	}, []);

	const getuserDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/user-details/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setUser(data.data);
		} catch {
			message.error("Network Error Please try again");
		}
	};

	function getLocalTime() {
		const date = new Date();
		const formattedDate = date.toLocaleString("en-US");
		setDate(formattedDate);
	}

	useEffect(() => {
		getLocalTime();
	}, []);

	setTimeout(() => {
		getLocalTime();
	}, 60);

	const toggleNotifications = () => {
		setShowNotifications(!showNotifications);
	};

	const handleClickOutside = (event) => {
		if (
			notificationsRef.current &&
			!notificationsRef.current.contains(event.target) &&
			!bellIconRef.current.contains(event.target)
		) {
			setShowNotifications(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="vendor-layout">
			<Layout>
				<Sider
					className="side"
					breakpoint="md"
					collapsedWidth="0"
					width={"225px"}
					style={{
						height: "calc(100vh - 100px)",
						position: "fixed",
						left: "0",
						top: "100px",
						bottom: 0,
						zIndex: 1,
					}}>
					<Menu
						mode="inline"
						theme="light"
						defaultSelectedKeys={defaultSelectedKeys()}
						className="menu"
						items={menuItems}
					/>
				</Sider>
				<Layout>
					<Header className="head">
						<div className="header-logo">
							<a href="/">
								<img alt="logo" src={logo} />
							</a>
						</div>
						<div className="user-details">
							<h3>Welcome, {user?.username}</h3>
							<p>{date}</p>
						</div>
						<button
							style={{ cursor: "pointer" }}
							ref={bellIconRef}
							onClick={toggleNotifications}
							className="notificationbtn">
							<BellOutlined />
						</button>
					</Header>

					{}
					{showNotifications ? (
						<div ref={notificationsRef} className="content">
							{" "}
							{}
							<Notifications />
						</div>
					) : (
						<div className="content">{children}</div>
					)}
				</Layout>
			</Layout>
		</div>
	);
};

export default Main;
