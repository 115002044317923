import React, { useEffect, useState } from "react";
import { Tabs, Table, Spin, Descriptions, Badge } from "antd";
import { useParams } from "react-router-dom";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Main from "./Layout";

const SpecificVendor = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [vendorData, setVendorData] = useState(null);
	const [invoices, setInvoices] = useState([]);
	const { apiurl, token, currentCountry } = useAuth();

	useEffect(() => {
		const fetchVendorDetails = async () => {
			try {
				const vendorResponse = await fetch(`${apiurl}/mydetails/vendor/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				const invoicesResponse = await fetch(`${apiurl}/vendor/myinvoices/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				if (!vendorResponse.ok || !invoicesResponse.ok) {
					throw new Error("Failed to fetch data");
				}

				const vendorData = await vendorResponse.json();
				const invoicesData = await invoicesResponse.json();

				setVendorData(vendorData);
				setInvoices(invoicesData);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchVendorDetails();
	}, [id, apiurl, token]);

	if (loading) {
		return (
			<Spin size="large" style={{ display: "block", margin: "100px auto" }} />
		);
	}

	if (!vendorData) {
		return <div className="error">Failed to load vendor data</div>;
	}

	const formattedRevenue = Number(
		vendorData.total_revenue.trim()
	).toLocaleString("en-IN", {
		style: "currency",
		currency: `${currentCountry.currency}`,
	});

	const invoiceColumns = [
		{ title: "Invoice ID", dataIndex: "invoice_id", key: "invoice_id" },
		{ title: "Amount", dataIndex: "amount", key: "amount" },
		{ title: "Date", dataIndex: "date", key: "date" },
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => (
				<Badge status={status === "Paid" ? "success" : "error"} text={status} />
			),
		},
	];

	return (
		<Main>
			<div className="vendor-dashboard-container">
				<div className="vendor-header">
					<div className="vendor-image">
						<img
							src={
								vendorData.logo ? apiurl + vendorData.logo : "/default-logo.png"
							}
							alt="Vendor Logo"
							style={{ width: 100, height: 100, borderRadius: "50%" }}
						/>
					</div>
					<div className="vendor-details">
						<h1 className="vendor-name">
							{vendorData.business_name} <span>({vendorData.username})</span>
						</h1>
						<div className="vendor-stats">
							<div className="stat-item">
								<div className="stat-value">{formattedRevenue}</div>
								<div className="stat-label">Total Revenue</div>
							</div>
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_products}</div>
								<div className="stat-label">Total Products</div>
							</div>
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_orders}</div>
								<div className="stat-label">Total Orders</div>
							</div>
						</div>
					</div>
				</div>

				<Tabs defaultActiveKey="1" className="vendor-tabs">
					{}
					<Tabs.TabPane tab="User Details" key="1">
						<Descriptions column={1} title="User Information">
							<Descriptions.Item label="Username">
								{vendorData.username}
							</Descriptions.Item>
							<Descriptions.Item label={<MailOutlined />}>
								{vendorData.contact_email || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label={<PhoneOutlined />}>
								{vendorData.contact_phone || "N/A"}
							</Descriptions.Item>
						</Descriptions>
					</Tabs.TabPane>

					{}
					<Tabs.TabPane tab="Business Details" key="2">
						<Descriptions column={1} title="Business Information">
							<Descriptions.Item label="Business Name">
								{vendorData.business_name}
							</Descriptions.Item>
							<Descriptions.Item label="Year of Establishment">
								{vendorData.year_of_establishment}
							</Descriptions.Item>
							<Descriptions.Item label="Business Type">
								{vendorData.business_type}
							</Descriptions.Item>
							<Descriptions.Item label="Operating Hours">
								{vendorData.operating_hours} hrs
							</Descriptions.Item>
						</Descriptions>
					</Tabs.TabPane>

					{}
					<Tabs.TabPane tab="Addresses" key="3">
						{}
						<Descriptions column={1} title="Business Address">
							<Descriptions.Item label="Address">
								{vendorData.business_address_line || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="City">
								{vendorData.business_address_city || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="State">
								{vendorData.business_address_state || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Country">
								{vendorData.business_address_country || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Pincode">
								{vendorData.business_address_pincode || "N/A"}
							</Descriptions.Item>
						</Descriptions>
						<br></br>
						{}
						<Descriptions column={1} title="Warehouse Address">
							<Descriptions.Item label="Address">
								{vendorData.warehouse_address_line || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="City">
								{vendorData.warehouse_address_city || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="State">
								{vendorData.warehouse_address_state || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Country">
								{vendorData.warehouse_address_country || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Pincode">
								{vendorData.warehouse_address_pincode || "N/A"}
							</Descriptions.Item>
						</Descriptions>
					</Tabs.TabPane>

					{}
					<Tabs.TabPane tab="Invoices" key="4">
						<Table
							dataSource={invoices}
							columns={invoiceColumns}
							pagination={{ pageSize: 5 }}
						/>
					</Tabs.TabPane>

					{}
					<Tabs.TabPane tab="Bank Details" key="5">
						<Descriptions column={1} title="Bank Information">
							<Descriptions.Item label="Bank Name">
								{vendorData.bank_name || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Account Number">
								{vendorData.account_number
									? vendorData.account_number.slice(0, 4) + "********"
									: "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="IFSC Code">
								{vendorData.ifsc_code || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Branch">
								{vendorData.branch || "N/A"}
							</Descriptions.Item>
							<Descriptions.Item label="Swift Code">
								{vendorData.swift_code || "N/A"}
							</Descriptions.Item>
						</Descriptions>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</Main>
	);
};

export default SpecificVendor;
