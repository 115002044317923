import React, { useState } from "react";
import { Input, Button, message, Tabs } from "antd";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../utils/useAuth";
import { Link, useNavigate } from "react-router-dom";
import "./auth.css";
import background from "./../../images/Login/background.png";

const { TabPane } = Tabs;

const LoginForm = () => {
	const { apiurl, handleLogin } = useAuth();
	const [loading, setLoading] = useState(false);
	const [otpSent, setOtpSent] = useState(false);
	const [loginMethod, setLoginMethod] = useState(null);
	const [otpResentLoading, setOtpResentLoading] = useState(false);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: "",
		phone: "",
		otp: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const sendOTP = async (method) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/sendotp/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					[method]: formData[method],
					otp_method: method === "email" ? "email_otp" : "mobile_otp",
				}),
			});
			const data = await response.json();
			if (response.ok) {
				setOtpSent(true);
				setLoginMethod(method);
				message.success(data.message || "OTP sent successfully");
			} else {
				message.error(data.error || "Failed to send OTP");
			}
		} catch (error) {
			message.error("An error occurred while sending OTP");
		} finally {
			setLoading(false);
		}
	};

	const verifyOTP = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/login/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					[loginMethod]: formData[loginMethod],
					otp: formData.otp,
					login_method: `${loginMethod}_otp`,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Login successfull!");
				handleLogin(data.access_token, data.role, data.countryCode);

				navigate("/");
			} else {
				message.error(data.error || "Invalid OTP or OTP expired");
			}
		} catch (error) {
			message.error("An error occurred while verifying OTP");
		} finally {
			setLoading(false);
		}
	};

	const handleResendOTP = async () => {
		setOtpResentLoading(true);
		try {
			const response = await fetch(`${apiurl}/sendotp/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: formData.email,
					phone: formData.phone,
					username: formData.username,
					otp_method: loginMethod === "email" ? "email_otp" : "mobile_otp",
				}),
			});

			const data = await response.json();

			if (response.ok) {
				message.success("OTP sent successfully");
			} else {
				message.error(data.error || "Failed to resend OTP");
			}
		} catch (error) {
			message.error("An error occurred while resending OTP");
		} finally {
			setOtpResentLoading(false);
		}
	};

	const handleGoogleLogin = async (response) => {
		setLoading(true);
		try {
			const googleToken = response.credential;
			const res = await fetch(`${apiurl}/signup/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					google_token: googleToken,
					signup_method: "google",
				}),
			});
			const data = await res.json();
			if (res.ok) {
				message.success("Logged in with Google successfully");
				handleLogin(data.access_token, data.role, data.countryCode);
				navigate("/");
			} else {
				message.error(data.error || "Google login failed");
			}
		} catch (error) {
			message.error("An error occurred during Google login");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="login-main">
			<img src={background} alt="sheEagle"></img>
			<div className="login-left">
				<button className="close-btn" onClick={() => navigate("/")}>
					X
				</button>
				<div className="tabs-content">
					<h2>Existing Member</h2>
					<p>Welcome Back!</p>
					<Tabs defaultActiveKey="1" onChange={(key) => setOtpSent(false)}>
						<TabPane tab="Login with Email" key="1">
							<label htmlFor="email">Email</label>
							<Input
								id="email"
								// placeholder="Enter your email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
								style={{ marginBottom: 8 }}
							/>
							{otpSent && loginMethod === "email" ? (
								<>
									<label htmlFor="otp-email">OTP</label>
									<Input
										id="otp-email"
										// placeholder="Enter OTP"
										name="otp"
										value={formData.otp}
										onChange={handleInputChange}
										style={{ marginBottom: 8 }}
									/>
									<div className="resend-btn">
										<Button
											type="text"
											onClick={handleResendOTP}
											disabled={loading}>
											Resend OTP
										</Button>
									</div>
									<Button
										type="primary"
										loading={loading}
										onClick={verifyOTP}
										style={{ width: "100%" }}>
										Login
									</Button>
								</>
							) : (
								<Button
									type="primary"
									loading={loading}
									onClick={() => sendOTP("email")}
									style={{ width: "100%" }}>
									Send OTP to Email
								</Button>
							)}
						</TabPane>

						<TabPane tab="Login with Mobile" key="2">
							<label htmlFor="phone">Phone Number</label>
							<Input
								id="phone"
								// placeholder="Enter your phone number"
								name="phone"
								value={formData.phone}
								onChange={handleInputChange}
								style={{ marginBottom: 8 }}
							/>
							{otpSent && loginMethod === "phone" ? (
								<>
									<label htmlFor="otp-phone">OTP</label>
									<Input
										id="otp-phone"
										// placeholder="Enter OTP"
										name="otp"
										value={formData.otp}
										onChange={handleInputChange}
										style={{ marginBottom: 8 }}
									/>
									<div className="resend-btn">
										<Button
											type="text"
											onClick={handleResendOTP}
											disabled={loading}>
											Resend OTP
										</Button>
									</div>
									<Button
										type="primary"
										loading={loading}
										onClick={verifyOTP}
										style={{ width: "100%" }}>
										Login
									</Button>
								</>
							) : (
								<Button
									type="primary"
									loading={loading}
									onClick={() => sendOTP("phone")}
									style={{ width: "100%" }}>
									Send OTP to Phone
								</Button>
							)}
						</TabPane>
					</Tabs>
				</div>
				<div className="or-option">
					<div className="line"></div>
					<div>OR</div>
					<div className="line"></div>
				</div>
				<div className="google-btn">
					<GoogleOAuthProvider clientId="667632613881-vl6quf1m8hoj99if4vsdutu1dq830nn5.apps.googleusercontent.com">
						<GoogleLogin
							type="icon"
							shape="circle"
							onSuccess={handleGoogleLogin}
							onError={() => message.error("Google login failed")}
						/>
					</GoogleOAuthProvider>
				</div>
				<div className="sign-up-text">
					<p>
						Didn't Have an Account. <Link to="/signup">Create New</Link>
					</p>
					<p>
						Login as a seller. <Link to="/login/vendor">Login</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
