import React, { createContext, useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import countries from "./Countries";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState(null);
	const [role, setRole] = useState(null);
	const [currentCountry, setCurrentCountry] = useState({
		code: "AE",
		name: "Dubai (UAE)",
		currency: "AED",
		symbol: "د.إ",
		flag: "🇦🇪",
		mobileCode: "+971",
	});

	// const apiurl = "http://localhost:8000";
	// const apiurl = "http://192.168.117.119:8000";
	const apiurl = "https://carrier.sheagle.online";

	useEffect(() => {
		const storedToken = localStorage.getItem("shEagletoken");
		const storedRole = localStorage.getItem("shEagleRole");
		const storedCurrentCountry = localStorage.getItem(
			"sheEagleSelectedCountry"
		);

		if (storedToken) {
			setToken(storedToken);
		} else {
			const sessionToken = sessionStorage.getItem("shEagletoken");
			if (sessionToken) {
				setToken(sessionToken);
			}
		}

		if (storedRole) {
			setRole(storedRole);
		} else {
			const sessionRole = sessionStorage.getItem("shEagleRole");
			if (sessionRole) {
				setRole(sessionRole);
			}
		}

		if (storedCurrentCountry) {
			const parsedCountry = JSON.parse(storedCurrentCountry);
			setCurrentCountry(parsedCountry);
		} else {
			const sessionCC = sessionStorage.getItem("sheEagleSelectedCountry");
			if (sessionCC) {
				const parsedCountry = JSON.parse(sessionCC);
				setCurrentCountry(parsedCountry);
			} else {
				fetchCurrentRegion();
			}
		}

		setIsLoading(false);
		verifyToken(storedToken);
	}, []);

	const fetchCurrentRegion = async () => {
		try {
			const response = await fetch("https://ipinfo.io/json");
			if (response.ok) {
				const data = await response.json();
				const countryCode = data.country;
				const matchingCountry = countries.find(
					(country) => country.code === countryCode
				);

				if (matchingCountry) {
					setCurrentCountry(matchingCountry);
					localStorage.setItem(
						"sheEagleSelectedCountry",
						JSON.stringify(matchingCountry)
					);
					sessionStorage.setItem(
						"sheEagleSelectedCountry",
						JSON.stringify(matchingCountry)
					);
				} else {
					console.error("Country not found for country code:", countryCode);
				}
			} else {
				console.error("Failed to fetch region:", response.statusText);
			}
		} catch (error) {
			console.error("Error fetching region:", error);
		}
	};

	const verifyToken = async (token) => {
		if (!token) return;

		try {
			const response = await fetch(`${apiurl}/verify-token/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ token }),
			});

			if (!response.ok) {
				setToken(null);
				setRole(null);
				localStorage.removeItem("shEagletoken");
				localStorage.removeItem("shEagleRole");
				sessionStorage.removeItem("shEagletoken");
				sessionStorage.removeItem("shEagleRole");
				return;
			}

			if (response.ok) {
				const data = await response.json();
				const matchedCountry = countries.find(
					(country) => country.mobileCode === data.countryCode
				);

				if (matchedCountry) {
					setCurrentCountry(matchedCountry);
					localStorage.setItem(
						"sheEagleSelectedCountry",
						JSON.stringify(matchedCountry)
					);
					sessionStorage.setItem(
						"sheEagleSelectedCountry",
						JSON.stringify(matchedCountry)
					);
				}
				if (data.role) {
					setRole(data.role);
					localStorage.setItem("shEagleRole", data.role);
					sessionStorage.setItem("shEagleRole", data.role);
				}
			} else {
				console.error("Failed to verify token.");
			}
		} catch (error) {
			setToken(null);
			setRole(null);
			localStorage.removeItem("shEagletoken");
			localStorage.removeItem("shEagleRole");
			sessionStorage.removeItem("shEagletoken");
			sessionStorage.removeItem("shEagleRole");
			console.error("Error verifying token:", error);
		}
	};

	const handleLogin = (token, role, countryCode) => {
		setToken(token);
		setRole(role);
		const matchedCountry = countries.find(
			(country) => country.mobileCode === countryCode
		);
		console.log(matchedCountry);
		if (matchedCountry) {
			setCountry(JSON.stringify(matchedCountry));
			localStorage.setItem(
				"sheEagleSelectedCountry",
				JSON.stringify(matchedCountry)
			);
		}
		localStorage.setItem("shEagletoken", token);
		localStorage.setItem("shEagleRole", role);
		handleSessionLogin(token, role, countryCode);
	};

	const handleSessionLogin = (token, role, countryCode) => {
		setToken(token);
		setRole(role);
		const matchedCountry = countries.find(
			(country) => country.mobileCode === countryCode
		);
		if (matchedCountry) {
			setCountry(JSON.stringify(matchedCountry));
			sessionStorage.setItem(
				"sheEagleSelectedCountry",
				JSON.stringify(matchedCountry)
			);
		}
		sessionStorage.setItem("shEagletoken", token);
		sessionStorage.setItem("shEagleRole", role);
	};

	const setCountry = (country) => {
		localStorage.setItem("sheEagleSelectedCountry", country);
		sessionStorage.setItem("sheEagleSelectedCountry", country);
		const parsedCountry = JSON.parse(country);
		setCurrentCountry(parsedCountry);
	};

	const handleLogout = () => {
		setToken(null);
		setRole(null);
		localStorage.removeItem("shEagletoken");
		localStorage.removeItem("shEagleRole");
		sessionStorage.removeItem("shEagletoken");
		sessionStorage.removeItem("shEagleRole");
	};

	if (isLoading) {
		return <Loader></Loader>;
	}

	return (
		<UserContext.Provider
			value={{
				handleLogin,
				handleSessionLogin,
				handleLogout,
				token,
				role,
				setToken,
				setRole,
				apiurl,
				currentCountry,
				setCurrentCountry,
				setCountry,
			}}>
			{children}
		</UserContext.Provider>
	);
};
