import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import {
	Modal,
	Button,
	Input,
	Rate,
	Upload,
	message,
	Image,
	Popconfirm,
	Drawer,
} from "antd";
import { FilterOutlined, UploadOutlined } from "@ant-design/icons";
import "./OrdersTab.css";
import deliverylogo from "./../../images/delivery.png";

const OrdersTab = () => {
	const [orders, setOrders] = useState([]);
	const [filteredOrders, setFilteredOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedOrderItem, setSelectedOrderItem] = useState(null);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedYears, setSelectedYears] = useState([]);
	const [selectedStatuses, setSelectedStatuses] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [rating, setRating] = useState(0);
	const [review, setReview] = useState("");
	const [images, setImages] = useState([]);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [givenReview, setGivenReview] = useState(null);
	const [isEditing, setIsEditing] = useState(false);

	const { apiurl, token, currentCountry } = useAuth();

	const currentYear = new Date().getFullYear();
	const availableYears = Array.from({ length: 4 }, (_, i) => currentYear - i);
	const statuses = ["confirmed", "shipped", "delivered", "processing"];

	useEffect(() => {
		fetchOrders();
	}, [token]);

	useEffect(() => {
		if (selectedOrderItem) {
			fetchReview();
		}
	}, [selectedOrderItem]);

	const fetchOrders = async () => {
		setLoading(true);
		let headers = {
			"Content-Type": "application/json",
			Authorization: token ? `Bearer ${token}` : undefined,
		};

		try {
			const response = await fetch(`${apiurl}/orders/`, { headers });
			if (response.ok) {
				const data = await response.json();
				setOrders(data || []);
				setFilteredOrders(data || []);
			} else {
				console.error("Failed to fetch orders");
			}
		} catch (error) {
			console.error("Error fetching orders:", error);
		} finally {
			setLoading(false);
		}
	};

	const fetchReview = async () => {
		setLoading(true);
		let headers = {
			"Content-Type": "application/json",
			Authorization: token ? `Bearer ${token}` : undefined,
		};

		try {
			const response = await fetch(
				`${apiurl}/fetchuserreviews/${selectedOrderItem.product_varient.id}`,
				{ headers }
			);
			if (response.ok) {
				const data = await response.json();
				setGivenReview({
					...data,
					images: await Promise.all(
						data.images.map(async (image) => {
							const response = await fetch(`${apiurl}${image.image}`);
							const blob = await response.blob();
							return new File([blob], "image.jpg", { type: blob.type });
						})
					),
				});
			}
		} catch (error) {
			console.error("Error fetching review:", error);
		} finally {
			setLoading(false);
		}
	};

	const applyFilters = () => {
		let filtered = orders;

		if (searchQuery) {
			filtered = filtered.filter((order) =>
				order.items.some((item) =>
					item.product_varient.product_name
						.toLowerCase()
						.includes(searchQuery.toLowerCase())
				)
			);
		}

		if (selectedYears.length > 0) {
			filtered = filtered.filter((order) =>
				selectedYears.includes(new Date(order.created_at).getFullYear())
			);
		}

		if (selectedStatuses.length > 0) {
			filtered = filtered.filter((order) =>
				selectedStatuses.includes(order.status)
			);
		}

		setFilteredOrders(filtered);
	};

	useEffect(() => {
		applyFilters();
	}, [searchQuery, selectedYears, selectedStatuses, orders]);

	const handleYearChange = (year) => {
		setSelectedYears((prev) =>
			prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
		);
	};

	const handleStatusChange = (status) => {
		setSelectedStatuses((prev) =>
			prev.includes(status)
				? prev.filter((s) => s !== status)
				: [...prev, status]
		);
	};

	const handleItemSelect = (item, order) => {
		setSelectedOrderItem(item);
		setSelectedOrder(order);
	};

	const handleBackToOrders = () => {
		setSelectedOrderItem(null);
	};

	const handleRateClick = () => {
		setShowModal(true);
		setIsEditing(false);
	};

	const handleDeleteReview = async () => {
		try {
			const response = await fetch(`${apiurl}/reviews/`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: givenReview.id }),
			});
			if (response.ok) {
				message.success("Review deleted successfully!");
				setGivenReview(null);
			} else {
				message.error("Failed to delete review.");
			}
		} catch (error) {
			message.error("Error deleting review: " + error.message);
		}
	};

	const handleModalClose = () => {
		setShowModal(false);
		setRating(0);
		setReview("");
		setImages([]);
	};

	const handleSubmitReview = async () => {
		const formData = new FormData();
		formData.append("rating", rating);

		formData.append("id", selectedOrderItem.product_varient.id);

		formData.append("review", review);
		images.forEach((image) => {
			formData.append("images", image);
		});

		try {
			const response = await fetch(`${apiurl}/reviews/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (response.ok) {
				message.success(
					isEditing
						? "Review updated successfully!"
						: "Review submitted successfully!"
				);
				handleModalClose();
				fetchReview();
			} else {
				const data = await response.json();
				message.error(data.detail);
			}
		} catch (error) {
			message.error("Error submitting review: " + error.message);
		}
	};

	const handleImageUpload = ({ fileList }) => {
		setImages(fileList.map((file) => file.originFileObj || file));
	};

	const formatDate = (date) => {
		return new Date(date).toLocaleDateString();
	};

	return (
		<div className="orders-tab">
			{selectedOrderItem ? (
				<div className="order-item-detail">
					<div className="delivery-address">
						<div className="address-header">
							<div className="add-c">
								<h4>
									<>Delivery Address</>
									<button className="download-invoice-btn">
										Download Invoice
									</button>
								</h4>
								<p>
									<strong>Name - </strong>
									<span>{selectedOrder.shipping_address.contact_name}</span>
								</p>
								<p>
									<strong>Address - </strong>
									<span>{selectedOrder.shipping_address.address}</span>
								</p>
								<p>
									<strong>Contact Number:</strong>{" "}
									<span>{selectedOrder.shipping_address.contact_number}</span>
								</p>
							</div>

							<div className="right-part">
								<img src={deliverylogo} alt="sheEagle" />
								<button className="download-invoice-btn">
									Download Invoice
								</button>
							</div>
						</div>
					</div>

					<div className="order-detail-card">
						<div className="product-info">
							<img
								src={
									apiurl + selectedOrderItem.product_varient.images[0]?.image ||
									"/default.jpg"
								}
								alt={selectedOrderItem.product_varient.product_name}
							/>
							<div>
								<h4>{selectedOrderItem.product_varient.product_name}</h4>
								<p>
									<span>
										{selectedOrderItem.product_varient.color.name || "N/A"}
									</span>{" "}
									•{" "}
									<span>{selectedOrderItem.product_varient.size || "N/A"}</span>
								</p>
								<p style={{ color: "red", fontWeight: "bold" }}>
									{currentCountry.currency} {selectedOrderItem.price}
								</p>
							</div>
						</div>
						<div className="right-status">
							<div className="order-timeline">
								<div className="timeline-point completed">
									Order Confirmed <br /> <span>Wed, 1st Dec</span>
								</div>
								<div className="timeline-point completed">
									Shipped <br /> <span>Thu, 2nd Dec</span>
								</div>
								<div className="timeline-point">
									Out For Delivery <br /> <span>Sun, 5th Dec</span>
								</div>
								<div className="timeline-point">
									Delivered <br /> <span>Tue, 7th Dec</span>
								</div>
								<div className="order-actions">
									{!givenReview && (
										<button className="rate-btn" onClick={handleRateClick}>
											★ Rate & Review Product
										</button>
									)}
									<button className="repeat-order-btn">
										⟳ Repeat this order
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="order-review">
						{givenReview && (
							<div className="review-card">
								<h4>Your Review</h4>
								<div className="review-header">
									<div>
										<h4>{givenReview.user.name}</h4>
										<Rate disabled value={givenReview.rating} />
									</div>
									<p>{formatDate(givenReview.created_at)}</p>
								</div>
								<p>{givenReview.review}</p>
								<div className="review-images">
									{givenReview.images.map((image, index) => (
										<Image
											key={index}
											src={URL.createObjectURL(image)}
											alt="review"
										/>
									))}
								</div>
								<div className="review-actions">
									<Popconfirm
										title="Are you sure you want to delete this review?"
										onConfirm={handleDeleteReview}
										onCancel={() => console.log("Deletion canceled")}
										okText="Yes"
										cancelText="No">
										<button>Delete</button>
									</Popconfirm>
								</div>
							</div>
						)}
					</div>

					<button className="back-btn" onClick={handleBackToOrders}>
						← Back to Orders
					</button>
				</div>
			) : (
				<>
					<div className="inp-filter-section">
						<input
							type="text"
							className="product-search-input"
							placeholder="Search orders..."
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<button className="show-mbl" onClick={() => setDrawerVisible(true)}>
							<FilterOutlined />
						</button>
					</div>

					<div className="orders-main">
						<div className="filters">
							<div className="filter-section">
								<h4>Filter by Year</h4>
								{availableYears.map((year) => (
									<label key={year}>
										<input
											type="checkbox"
											value={year}
											checked={selectedYears.includes(year)}
											onChange={() => handleYearChange(year)}
										/>
										{year}
									</label>
								))}
							</div>

							<div className="filter-section">
								<h4>Filter by Status</h4>
								{statuses.map((status) => (
									<label key={status}>
										<input
											type="checkbox"
											value={status}
											checked={selectedStatuses.includes(status)}
											onChange={() => handleStatusChange(status)}
										/>
										{status.charAt(0).toUpperCase() + status.slice(1)}
									</label>
								))}
							</div>
						</div>

						<div className="orders-list">
							{loading ? (
								<p>Loading...</p>
							) : filteredOrders.length > 0 ? (
								filteredOrders.map((order) =>
									order.items.map((item) => (
										<div
											key={item.id}
											className="order-card"
											onClick={() => handleItemSelect(item, order)}>
											<img
												src={
													apiurl + item.product_varient.images[0]?.image ||
													"/default.jpg"
												}
												alt={item.product_varient.product_name}
											/>
											<div className="order-item-det">
												<div>
													<h4>{item.product_varient.product_name}</h4>
													<div className="prods-varients">
														<p>{item.product_varient.color.name}</p>|
														<p>{item.product_varient.size}</p>
													</div>
													<p className="price">
														{currentCountry.currency} {item.price}
													</p>
												</div>
												<div className="status">
													<p className={order.status}></p>
													<p>{order.status}</p>
												</div>
											</div>
										</div>
									))
								)
							) : (
								<p>No orders found.</p>
							)}
						</div>
					</div>
				</>
			)}

			<Modal
				title="Rate and Review"
				open={showModal}
				destroyOnClose
				onCancel={handleModalClose}
				footer={[
					<Button key="cancel" onClick={handleModalClose}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={handleSubmitReview}
						className="review-submit-btn">
						Submit
					</Button>,
				]}>
				<div className="review-form">
					<div>
						<span>Rating: </span>
						<Rate value={rating} onChange={setRating} />
					</div>
					<div>
						<span>Review:</span>
						<Input.TextArea
							value={review}
							onChange={(e) => setReview(e.target.value)}
							rows={4}
						/>
					</div>
					<div>
						<span>Upload Images:</span>
						<Upload
							listType="picture"
							multiple
							accept="image/*"
							onChange={handleImageUpload}
							beforeUpload={() => false}>
							<Button icon={<UploadOutlined />}>Upload</Button>
						</Upload>
					</div>
				</div>
			</Modal>
			<Drawer
				title="Filter Products"
				placement="bottom"
				className="filters-drawer"
				onClose={() => setDrawerVisible(false)}
				open={drawerVisible}
				width={300}>
				<div className="mb-filters">
					<div className="filter-section">
						<h4>Filter by Year</h4>
						<div>
							{availableYears.map((year) => (
								<label key={year}>
									<input
										type="checkbox"
										value={year}
										checked={selectedYears.includes(year)}
										onChange={() => handleYearChange(year)}
									/>
									{year}
								</label>
							))}
						</div>
					</div>

					<div className="filter-section">
						<h4>Filter by Status</h4>
						<div>
							{statuses.map((status) => (
								<label key={status}>
									<input
										type="checkbox"
										value={status}
										checked={selectedStatuses.includes(status)}
										onChange={() => handleStatusChange(status)}
									/>
									{status.charAt(0).toUpperCase() + status.slice(1)}
								</label>
							))}
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

export default OrdersTab;
