import React, { useState } from "react";
import { Input, Button, message, Radio, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import background from "./../../images/Login/background.png";
import countries from "../utils/Countries";

const { Option } = Select;

const Signup = () => {
	const { apiurl, handleLogin } = useAuth();
	const [loading, setLoading] = useState(false);
	const [otpSent, setOtpSent] = useState(false);
	const [otpResentLoading, setOtpResentLoading] = useState(false);

	const [formData, setFormData] = useState({
		username: "",
		email: "",
		phone: "",
		country_code: "+1",
		otp: "",
		gender: "Male",
	});

	const navigate = useNavigate();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSelectChange = (value, key) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
	};

	const handleValidate = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/sendotp/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: formData.email,
					phone: formData.phone,
					username: formData.username,
					otp_method: "both",
				}),
			});

			const data = await response.json();

			if (response.ok) {
				message.success("OTP sent successfully");
				setOtpSent(true);
			} else {
				message.error(data.error || "Failed to send OTP");
			}
		} catch (error) {
			message.error("An error occurred while sending OTP");
		} finally {
			setLoading(false);
		}
	};

	const handleSignup = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/signup/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					username: formData.username,
					email: formData.email,
					phone: formData.phone,
					otp: formData.otp,
					country_code: formData.country_code,
					gender: formData.gender,
					signup_method: "normal",
				}),
			});

			const data = await response.json();

			if (response.ok) {
				message.success("Registered successfully!");
				handleLogin(data.access_token, data.role, data.countryCode);
				navigate("/");
			} else {
				message.error(data.error || "Failed to register");
			}
		} catch (error) {
			message.error("An error occurred during registration");
		} finally {
			setLoading(false);
		}
	};

	const handleResendOTP = async () => {
		setOtpResentLoading(true);
		try {
			const response = await fetch(`${apiurl}/sendotp/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: formData.email,
					phone: formData.phone,
					username: formData.username,
					otp_method: "both",
				}),
			});

			const data = await response.json();

			if (response.ok) {
				message.success("OTP resent successfully");
			} else {
				message.error(data.error || "Failed to resend OTP");
			}
		} catch (error) {
			message.error("An error occurred while resending OTP");
		} finally {
			setOtpResentLoading(false);
		}
	};

	const handleGoogleLogin = async (response) => {
		setLoading(true);
		try {
			const googleToken = response.credential;
			const res = await fetch(`${apiurl}/signup/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					google_token: googleToken,
					signup_method: "google",
				}),
			});
			const data = await res.json();
			if (res.ok) {
				message.success("Logged in with Google successfully");
				handleLogin(data.access_token, data.role, data.countryCode);
				navigate("/");
			} else {
				message.error(data.error || "Google login failed");
			}
		} catch (error) {
			message.error("An error occurred during Google login");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="login-main sign-up-main">
			<img src={background} alt="sheEagle"></img>
			<div className="login-left">
				<button className="close-btn" onClick={() => navigate("/")}>
					X
				</button>
				<div className="tabs-content">
					<h2>New User</h2>
					<p>Sign Up Now!</p>
					<div>
						<label htmlFor="username">Buyers Name</label>
						<Input
							id="username"
							// placeholder="Enter buyers username"
							name="username"
							value={formData.username}
							onChange={handleInputChange}
							style={{ marginBottom: 8 }}
						/>

						<label htmlFor="email">Buyers Email</label>
						<Input
							id="email"
							// placeholder="Enter buyers email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							style={{ marginBottom: 8 }}
						/>

						<label htmlFor="country_code">Buyers Country</label>
						<Select
							id="country_code"
							value={formData.country_code}
							onChange={(value) => handleSelectChange(value, "country_code")}
							style={{ width: "100%", marginBottom: 8 }}>
							{countries.map((code) => (
								<Option key={code.mobileCode} value={code.mobileCode}>
									{code.name} ({code.mobileCode})
								</Option>
							))}
						</Select>

						<label htmlFor="phone">Buyers Contact</label>
						<Input
							id="phone"
							// placeholder="Enter buyers contact number"
							name="phone"
							minLength={10}
							maxLength={10}
							value={formData.phone}
							onChange={handleInputChange}
							style={{ marginBottom: 8 }}
						/>

						<label htmlFor="gender">Gender</label>
						<br></br>
						<br></br>
						<Radio.Group
							name="gender"
							value={formData.gender}
							onChange={handleInputChange}
							style={{ marginBottom: 8 }}>
							<Radio value="Male">Male</Radio>
							<Radio value="Female">Female</Radio>
							<Radio value="Rather Not Say">Rather Not Say</Radio>
						</Radio.Group>

						{!otpSent ? (
							<Button
								type="primary"
								loading={loading}
								onClick={handleValidate}
								style={{ width: "100%" }}>
								Validate and Send OTP
							</Button>
						) : (
							<div>
								<label htmlFor="otp">OTP</label>
								<Input
									id="otp"
									// placeholder="Enter OTP"
									name="otp"
									value={formData.otp}
									onChange={handleInputChange}
									style={{ marginBottom: 8 }}
								/>
								<div className="resend-btn">
									<Button
										type="link"
										loading={otpResentLoading}
										onClick={handleResendOTP}
										disabled={otpResentLoading}>
										Resend OTP
									</Button>
								</div>
								<Button
									type="primary"
									loading={loading}
									onClick={handleSignup}
									style={{ width: "100%" }}>
									Sign Up
								</Button>
							</div>
						)}

						{}
						<div className="sign-up-text">
							<p>
								Already Had an Account. <Link to="/login">Login</Link>
							</p>
							<p>
								Not a user. Click <Link to="/vendor/signup">Here</Link> to
								signup as a vendor.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signup;
