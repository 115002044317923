import React from "react";
import Main from "../user/Layout";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined, InstagramOutlined } from "@ant-design/icons";
import "./about.css";
import img1 from "./../../images/about/1.png";
import img2 from "./../../images/about/2.png";
import img3 from "./../../images/about/3.png";
import img4 from "./../../images/about/4.png";
import img5 from "./../../images/about/5.png";
import img6 from "./../../images/about/6.png";
import quote from "./../../images/about/quote.png";
import sign from "./../../images/about/sign.png";

const About = () => {
	return (
		<Main>
			<div className="about-main">
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to="/">
							<HomeOutlined />
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>About</Breadcrumb.Item>
				</Breadcrumb>
				<div className="sec-1">
					<div className="left">
						<img src={quote} alt="about" />
						<p>
							Discover global fashion like never before at SHEagle! Explore a
							curated collection of clothing, accessories, and jewelry from
							India, the Middle East, and beyond. Embrace tradition, modernity,
							and unique craftsmanship with every purchase—delivered worldwide
							to your doorstep
						</p>
						<img src={sign} alt="sheagle-sign" />
					</div>
					<div className="right">
						<img src={img1} alt="about" />
					</div>
				</div>
				<h3>A Few Words About Us</h3>
				<div className="sec-2">
					<div className="left">
						<img src={img2} alt="about" />
					</div>
					<div className="right">
						<p>
							SHEagle is a global fashion marketplace that connects cultures
							through unique clothing, accessories, and jewelry from India, the
							Middle East, and beyond. Our mission is to provide a borderless
							fashion experience that celebrates creativity and cultural
							heritage, empowering local artisans and vendors by giving them a
							global platform to showcase their designs
						</p>
					</div>
				</div>
				<div className="sec-3">
					<div className="right">
						<p>
							We believe fashion is more than just clothing—it's a way to
							connect with stories and traditions. Offering flexible purchasing
							options and reliable international shipping, we ensure every item
							meets the highest standards of quality. Join us in celebrating the
							beauty of global fashion!
						</p>
					</div>
					<div className="left">
						<img src={img3} alt="about" />
					</div>
				</div>
				<h3>Unique Selling Points</h3>
				<div className="sec-4">
					<div className="left">
						<img src={img4} alt="about" />
					</div>
					<div className="right">
						<ol start={1}>
							<li>Global Multi-Vendor Fashion Marketplace</li>
							<li>Celebration of Cultural Diversity</li>
							<li>Seamless International Shipping</li>
							<li>Diverse Product Categories</li>
							<li>Vendor-Friendly Ecosystem</li>
						</ol>
					</div>
				</div>
				<div className="sec-5">
					<div className="left">
						<ol start={6}>
							<li>Customizable Shopping Options</li>
							<li>Fashion For Modern Lifestyles</li>
							<li>Transparent Customer Policies</li>
							<li>Empowering Vendors Globally</li>
							<li>Purpose-Driven Mission</li>
						</ol>
					</div>
					<div className="right">
						<img src={img5} alt="about" />
					</div>
				</div>
				<h3>
					<InstagramOutlined /> <span>#sheaglefashion</span> On Instagram
				</h3>
				<div className="sec-6">
					<img src={img6} alt="about" />
				</div>
			</div>
		</Main>
	);
};

export default About;
