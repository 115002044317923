import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Main from '../Layout';
import { useAuth } from '../../utils/useAuth';
import { Button } from 'antd';

const SpecificBlogAdmin = () => {
    const { apiurl, token } = useAuth();
    const { id } = useParams(); // Get blog ID from URL
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchBlog = async () => {
        try {
            const response = await fetch(`${apiurl}/admin/get_blogs/?blog_id=${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                console.error("Unable to fetch the data");
            } else {
                const result = await response.json();
                setBlog(result);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token) {
            fetchBlog();
        }
    }, [token, id]);

    const handleAcceptOrReject = async (approval_status) => {
        try {
            const response = await fetch(`${apiurl}/admin/approve_blog_post/?blog_id=${id}&is_approved=${approval_status}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json();
            if (data.error) {
                console.error(data.error);
            } else {
                console.log(data.message);
                setBlog((prev) => ({ ...prev, is_approved: approval_status }));
            }
        } catch (e) {
            console.error(e);
        }
    };

    if (loading) {
        return <Main>Loading...</Main>;
    }

    if (!blog) {
        return <Main>Blog not found.</Main>;
    }

    return (
        <Main>
            <h1>{blog.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: blog.content }}></p> {/* Render HTML content */}
            <p><strong>Created By:</strong> {blog.user?.username || "Unknown"}</p>
            <p><strong>Created At:</strong> {new Date(blog.created_at).toLocaleString()}</p>

            {blog.is_approved ? (
                <p><strong>Status:</strong> Approved</p>
            ) : (
                <div>
                    <Button type="primary" onClick={() => handleAcceptOrReject(true)}>Accept</Button>
                </div>
            )}
        </Main>
    );
};

export default SpecificBlogAdmin;
