const countries = [
	{
		code: "AU",
		name: "Australia",
		currency: "AUD",
		symbol: "A$",
		flag: "🇦🇺",
		mobileCode: "+61",
	},
	{
		code: "AT",
		name: "Austria",
		currency: "EUR",
		symbol: "€",
		flag: "🇦🇹",
		mobileCode: "+43",
	},
	{
		code: "BH",
		name: "Bahrain",
		currency: "BHD",
		symbol: ".د.ب",
		flag: "🇧🇭",
		mobileCode: "+973",
	},
	{
		code: "BD",
		name: "Bangladesh",
		currency: "BDT",
		symbol: "৳",
		flag: "🇧🇩",
		mobileCode: "+880",
	},
	{
		code: "BR",
		name: "Brazil",
		currency: "BRL",
		symbol: "R$",
		flag: "🇧🇷",
		mobileCode: "+55",
	},
	{
		code: "KH",
		name: "Cambodia",
		currency: "KHR",
		symbol: "៛",
		flag: "🇰🇭",
		mobileCode: "+855",
	},
	{
		code: "CA",
		name: "Canada",
		currency: "CAD",
		symbol: "C$",
		flag: "🇨🇦",
		mobileCode: "+1",
	},
	{
		code: "CN",
		name: "China",
		currency: "CNY",
		symbol: "¥",
		flag: "🇨🇳",
		mobileCode: "+86",
	},
	{
		code: "CY",
		name: "Cyprus",
		currency: "EUR",
		symbol: "€",
		flag: "🇨🇾",
		mobileCode: "+357",
	},
	{
		code: "EG",
		name: "Egypt",
		currency: "EGP",
		symbol: "E£",
		flag: "🇪🇬",
		mobileCode: "+20",
	},
	{
		code: "ET",
		name: "Ethiopia",
		currency: "ETB",
		symbol: "Br",
		flag: "🇪🇹",
		mobileCode: "+251",
	},
	{
		code: "FR",
		name: "France",
		currency: "EUR",
		symbol: "€",
		flag: "🇫🇷",
		mobileCode: "+33",
	},
	{
		code: "DE",
		name: "Germany",
		currency: "EUR",
		symbol: "€",
		flag: "🇩🇪",
		mobileCode: "+49",
	},
	{
		code: "HU",
		name: "Hungary",
		currency: "HUF",
		symbol: "Ft",
		flag: "🇭🇺",
		mobileCode: "+36",
	},
	{
		code: "IN",
		name: "India",
		currency: "INR",
		symbol: "₹",
		flag: "🇮🇳",
		mobileCode: "+91",
	},
	{
		code: "ID",
		name: "Indonesia",
		currency: "IDR",
		symbol: "Rp",
		flag: "🇮🇩",
		mobileCode: "+62",
	},
	{
		code: "IR",
		name: "Iran",
		currency: "IRR",
		symbol: "﷼",
		flag: "🇮🇷",
		mobileCode: "+98",
	},
	{
		code: "IQ",
		name: "Iraq",
		currency: "IQD",
		symbol: "ع.د",
		flag: "🇮🇶",
		mobileCode: "+964",
	},
	{
		code: "IE",
		name: "Ireland",
		currency: "EUR",
		symbol: "€",
		flag: "🇮🇪",
		mobileCode: "+353",
	},
	{
		code: "IL",
		name: "Israel",
		currency: "ILS",
		symbol: "₪",
		flag: "🇮🇱",
		mobileCode: "+972",
	},
	{
		code: "IT",
		name: "Italy",
		currency: "EUR",
		symbol: "€",
		flag: "🇮🇹",
		mobileCode: "+39",
	},
	{
		code: "JM",
		name: "Jamaica",
		currency: "JMD",
		symbol: "J$",
		flag: "🇯🇲",
		mobileCode: "+1-876",
	},
	{
		code: "JP",
		name: "Japan",
		currency: "JPY",
		symbol: "¥",
		flag: "🇯🇵",
		mobileCode: "+81",
	},
	{
		code: "KE",
		name: "Kenya",
		currency: "KES",
		symbol: "KSh",
		flag: "🇰🇪",
		mobileCode: "+254",
	},
	{
		code: "KW",
		name: "Kuwait",
		currency: "KWD",
		symbol: "د.ك",
		flag: "🇰🇼",
		mobileCode: "+965",
	},
	{
		code: "MY",
		name: "Malaysia",
		currency: "MYR",
		symbol: "RM",
		flag: "🇲🇾",
		mobileCode: "+60",
	},
	{
		code: "NP",
		name: "Nepal",
		currency: "NPR",
		symbol: "रू",
		flag: "🇳🇵",
		mobileCode: "+977",
	},
	{
		code: "NZ",
		name: "New Zealand",
		currency: "NZD",
		symbol: "NZ$",
		flag: "🇳🇿",
		mobileCode: "+64",
	},
	{
		code: "NG",
		name: "Nigeria",
		currency: "NGN",
		symbol: "₦",
		flag: "🇳🇬",
		mobileCode: "+234",
	},
	{
		code: "KP",
		name: "North Korea",
		currency: "KPW",
		symbol: "₩",
		flag: "🇰🇵",
		mobileCode: "+850",
	},
	{
		code: "OM",
		name: "Oman",
		currency: "OMR",
		symbol: "ر.ع.",
		flag: "🇴🇲",
		mobileCode: "+968",
	},
	{
		code: "PK",
		name: "Pakistan",
		currency: "PKR",
		symbol: "₨",
		flag: "🇵🇰",
		mobileCode: "+92",
	},
	{
		code: "PH",
		name: "Philippines",
		currency: "PHP",
		symbol: "₱",
		flag: "🇵🇭",
		mobileCode: "+63",
	},
	{
		code: "PL",
		name: "Poland",
		currency: "PLN",
		symbol: "zł",
		flag: "🇵🇱",
		mobileCode: "+48",
	},
	{
		code: "QA",
		name: "Qatar",
		currency: "QAR",
		symbol: "ر.ق",
		flag: "🇶🇦",
		mobileCode: "+974",
	},
	{
		code: "SA",
		name: "Saudi Arabia",
		currency: "SAR",
		symbol: "﷼",
		flag: "🇸🇦",
		mobileCode: "+966",
	},
	{
		code: "SG",
		name: "Singapore",
		currency: "SGD",
		symbol: "S$",
		flag: "🇸🇬",
		mobileCode: "+65",
	},
	{
		code: "ZA",
		name: "South Africa",
		currency: "ZAR",
		symbol: "R",
		flag: "🇿🇦",
		mobileCode: "+27",
	},
	{
		code: "KR",
		name: "South Korea",
		currency: "KRW",
		symbol: "₩",
		flag: "🇰🇷",
		mobileCode: "+82",
	},
	{
		code: "ES",
		name: "Spain",
		currency: "EUR",
		symbol: "€",
		flag: "🇪🇸",
		mobileCode: "+34",
	},
	{
		code: "LK",
		name: "Sri Lanka",
		currency: "LKR",
		symbol: "Rs",
		flag: "🇱🇰",
		mobileCode: "+94",
	},
	{
		code: "CH",
		name: "Switzerland",
		currency: "CHF",
		symbol: "Fr",
		flag: "🇨🇭",
		mobileCode: "+41",
	},
	{
		code: "TR",
		name: "Turkey",
		currency: "TRY",
		symbol: "₺",
		flag: "🇹🇷",
		mobileCode: "+90",
	},
	{
		code: "AE",
		name: "United Arab Emirates",
		currency: "AED",
		symbol: "د.إ",
		flag: "🇦🇪",
		mobileCode: "+971",
	},
	{
		code: "GB",
		name: "United Kingdom",
		currency: "GBP",
		symbol: "£",
		flag: "🇬🇧",
		mobileCode: "+44",
	},
	{
		code: "US",
		name: "United States of America",
		currency: "USD",
		symbol: "$",
		flag: "🇺🇸",
		mobileCode: "+1",
	},
	{
		code: "VN",
		name: "Vietnam",
		currency: "VND",
		symbol: "₫",
		flag: "🇻🇳",
		mobileCode: "+84",
	},
	{
		code: "YE",
		name: "Yemen",
		currency: "YER",
		symbol: "﷼",
		flag: "🇾🇪",
		mobileCode: "+967",
	},
];

export default countries;
