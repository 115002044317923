import React, { useEffect, useState } from "react";
import {
	Card,
	Typography,
	Descriptions,
	Table,
	Badge,
	Spin,
	Breadcrumb,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import {
	UserOutlined,
	ShoppingCartOutlined,
	ClockCircleOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	HeatMapOutlined,
} from "@ant-design/icons";
import "./SpecificUser.css";
import Main from "./Layout";

const SpecificUser = () => {
	const { id } = useParams();
	const { apiurl, token, currentCountry } = useAuth();
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				const response = await fetch(`${apiurl}/admin/userdetails/${id}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error("Failed to fetch user details");
				}

				const data = await response.json();
				setUserData(data);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchUserDetails();
	}, [apiurl, id, token]);

	const columns = [
		{
			title: "Order ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "No of Products",
			dataIndex: "product",
			key: "product",
		},
		{
			title: "Total",
			dataIndex: "total_amount",
			key: "total_amount",
			render: (total_amount) =>
				`${currentCountry.currency + " " + total_amount}`,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => (
				<Badge
					status={
						status === "Processing"
							? "processing"
							: status === "Delivered"
							? "success"
							: "default"
					}
					text={status}
				/>
			),
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (date) => formatDate(date),
		},
	];

	if (loading) {
		return <Spin size="large" className="loading-spinner" />;
	}

	if (!userData) {
		return (
			<Typography.Text type="danger">Failed to load user data</Typography.Text>
		);
	}

	const formatDate = (dateobj) => {
		const date = new Date(dateobj);
		return date.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
	};

	const {
		user: { username, email, phone_number },
		last_order,
		orders,
		cart,
		pendingOrders,
		address,
		deliveredOrders,
		returnedOrders,
		cancelledOrders,
	} = userData;

	return (
		<Main>
			<Breadcrumb>
				<Breadcrumb.Item
					onClick={() => navigate(-1)}
					style={{ cursor: "pointer" }}>
					Users
				</Breadcrumb.Item>
				<Breadcrumb.Item>{username}</Breadcrumb.Item>
			</Breadcrumb>
			<div className="customer-details-container">
				<div className="customer-details-left">
					<div className="customer-profile-card">
						<div className="customer-profile">
							<div className="customer-avatar">
								{username.charAt(0).toUpperCase()}
							</div>
							<div className="customer-info">
								<h1 className="customer-name">{username}</h1>
							</div>
						</div>
						<Descriptions className="customer-profile-descriptions" column={1}>
							<Descriptions.Item label="E-mail">{email}</Descriptions.Item>
							<Descriptions.Item label="Phone Number">
								{phone_number}
							</Descriptions.Item>
							<Descriptions.Item label="Last Order">
								{last_order ? formatDate(last_order) : "N/A"}
							</Descriptions.Item>
						</Descriptions>
					</div>
					<div className="customer-address-card">
						<h2 className="address-title">Address</h2>
						{address.map((addr, index) => (
							<div key={index} className="address-card">
								<span>{addr.city}, </span>
								<span>{addr.state}, </span>
								<span>{addr.country}, </span>
								<span>{addr.pincode}, </span>
								<span>{addr.address}</span>
							</div>
						))}
					</div>
				</div>

				<div className="customer-details-right">
					<div className="customer-stats">
						<div className="stat-card">
							<ShoppingCartOutlined className="stat-icon" />
							<div className="stat-label">Total Orders</div>
							<div className="stat-value">{orders.length}</div>
						</div>
						<div className="stat-card">
							<UserOutlined className="stat-icon" />
							<div className="stat-label">Abandoned Cart</div>
							<div className="stat-value">{cart}</div>
						</div>
					</div>
					<div className="customer-stats-2nd-row">
						<ClockCircleOutlined className="stat-icon" />
						<div className="rw">
							<div className="stat-card">
								<div className="stat-label">Pending</div>
								<div className="stat-value">{pendingOrders}</div>
							</div>
							<div className="stat-card">
								<div className="stat-label">Completed</div>
								<div className="stat-value">{deliveredOrders}</div>
							</div>
							<div className="stat-card">
								<div className="stat-label">Cancelled</div>
								<div className="stat-value">{cancelledOrders}</div>
							</div>
							<div className="stat-card">
								<div className="stat-label">Returned</div>
								<div className="stat-value">{returnedOrders}</div>
							</div>
						</div>
					</div>

					<div className="customer-transaction-card">
						<h2 className="transaction-title">Transaction History</h2>
						<Table
							columns={columns}
							dataSource={orders.map((txn) => ({
								id: txn.id,
								product: txn.items.length,
								total_amount: txn.total_amount,
								status: txn.status,
								date: txn.created_at,
								key: txn.id,
							}))}
							pagination={{ pageSize: 5 }}
							className="transaction-table"
						/>
					</div>
				</div>
			</div>
		</Main>
	);
};

export default SpecificUser;
