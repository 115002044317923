import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import ProductCard from "../user/Productcard";
import { message } from "antd";
import "./Wishlist.css";

const Wishlist = () => {
	const [wishlistitems, setWishlistitems] = useState([]);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		fetchWishlist();
	}, [token]);

	const fetchWishlist = async () => {
		let headers = {
			"Content-Type": "application/json",
		};

		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}

		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				headers: headers,
			});

			if (response.ok) {
				const data = await response.json();
				setWishlistitems(data.items || []);
			} else {
				console.error("Failed to fetch wishlist");
				setWishlistitems([]);
			}
		} catch (error) {
			console.error("Error fetching wishlist:", error);
			setWishlistitems([]);
		}
	};

	const toogleWishlist = async (prodid, status) => {
		if (!token) {
			message.warning("Please login to add to wishlist");
			return;
		}

		let method = status === true ? "POST" : "DELETE";
		const response = await fetch(`${apiurl}/wishlist/`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			method: method,
			body: JSON.stringify({ item_id: prodid }),
		});
		const data = await response.json();
		if (response.ok) {
			fetchWishlist();
			message.success(data.message);
		} else {
			console.log("Error toggling wishlist");
		}
	};

	return (
		<div className="wishlist-items-main">
			{wishlistitems.length > 0 ? (
				wishlistitems.map((product, index) => (
					<div key={index}>
						<ProductCard
							product={product.product_varient}
							toogleWishlist={toogleWishlist}
						/>
					</div>
				))
			) : (
				<p>No items in your wishlist</p>
			)}
		</div>
	);
};

export default Wishlist;
