import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Select,
	Button,
	Table,
	Modal,
	message,
	Upload,
	InputNumber,
	Space,
	Popconfirm,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Main from "./Layout";
import "./AddProduct.css";

const { Option } = Select;
const { TextArea } = Input;

const AddProductForm = () => {
	const { apiurl, token, currentCountry } = useAuth();
	const [productDetails, setProductDetails] = useState({
		name: "",
		mainCategory: "",
		category: "",
		subcategory: "",
		description: "",
		variants: [],
		sizeGuide: [],
	});
	const [mainCategories, setMainCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [filteredSubcategories, setFilteredSubcategories] = useState([]);
	const [subcategories, setSubcategories] = useState([]);
	const [colors, setColors] = useState([]);
	const [countries, setCountries] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editingVariant, setEditingVariant] = useState(null);
	const [newVariant, setNewVariant] = useState({
		size: "",
		color: "",
		stock_quantity: 0,
		weight: 0,
		length: 0,
		width: 0,
		common_price: 0,
		height: 0,
		images: [],
		prices: [],
		videos: [],
	});

	const fetchData = async (endpoint, setter) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}${endpoint}`, {
				method: "GET",
				headers: { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			if (response.ok) {
				setter(data);
			} else {
				console.error(`Failed to fetch ${endpoint.slice(1)}`);
			}
		} catch (error) {
			console.error(`Error fetching ${endpoint.slice(1)}:`, error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData("/maincategories", setMainCategories);
		fetchData("/categories", setCategories);
		fetchData("/subcategories", setSubcategories);
		fetchData("/colors", setColors);
		fetchData("/countries", setCountries);
	}, [apiurl, token]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setProductDetails((prev) => ({ ...prev, [name]: value }));
	};

	const handleSelectChange = (value, name) => {
		setProductDetails((prev) => ({ ...prev, [name]: value }));
	};

	const handleVariantImageUpload = ({ fileList }) => {
		if (editingVariant !== null) {
			setProductDetails((prev) => ({
				...prev,
				variants: prev.variants.map((v, index) =>
					index === editingVariant ? { ...v, images: fileList } : v
				),
			}));
		} else {
			setNewVariant((prev) => ({ ...prev, images: fileList }));
		}
	};

	const handleAddVariant = () => {
		if (editingVariant !== null) {
			setProductDetails((prev) => ({
				...prev,
				variants: prev.variants.map((v, index) =>
					index === editingVariant ? newVariant : v
				),
			}));
			setEditingVariant(null);
		} else {
			setProductDetails((prev) => ({
				...prev,
				variants: [...prev.variants, newVariant],
			}));
		}
		setNewVariant({
			size: "",
			color: "",
			stock_quantity: 0,
			weight: 0,
			length: 0,
			width: 0,
			height: 0,
			images: [],
			prices: [],
			video: [],
		});
		setIsModalVisible(false);
	};

	const handleEditVariant = (index) => {
		setEditingVariant(index);
		setNewVariant(productDetails.variants[index]);
		setIsModalVisible(true);
	};

	const handleDeleteVariant = (index) => {
		setProductDetails((prev) => ({
			...prev,
			variants: prev.variants.filter((_, i) => i !== index),
		}));
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("name", productDetails.name);
			formData.append("mainCategory", productDetails.mainCategory);
			formData.append("category", productDetails.category);
			formData.append("subcategory", productDetails.subcategory);
			formData.append("description", productDetails.description);

			const variantsData = productDetails.variants.map((variant) => ({
				color_id: variant.color,
				size: variant.size,
				stock_quantity: variant.stock_quantity,
				weight: variant.weight,
				length: variant.length,
				width: variant.width,
				height: variant.height,
				common_price: commonPrice,
				prices: variant.prices.map((price) => ({
					country_id: price.country_id,
					price: price.price,
					discount: price.discount,
					discount_type: price.discount_type,
				})),
			}));

			formData.append("varients", JSON.stringify(variantsData));

			productDetails.variants.forEach((variant, index) => {
				variant.images.forEach((file, fileIndex) => {
					formData.append(
						`images_${variant.color}_${variant.size}`,
						file.originFileObj || file
					);
				});
			});

			productDetails.variants.forEach((variant, index) => {
				variant.videos?.forEach((file, fileIndex) => {
					formData.append(
						`videos_${variant.color}_${variant.size}`,
						file.originFileObj || file
					);
				});
			});
			productDetails.sizeGuide.forEach((file, fileIndex) => {
				formData.append(`size_guide`, file.originFileObj || file);
			});

			const response = await fetch(`${apiurl}/products/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Product added successfully");
				setProductDetails({
					name: "",
					mainCategory: "",
					category: "",
					subcategory: "",
					description: "",
					weight: "",
					variants: [],
					sizeGuide: [],
				});
			} else {
				message.error(data.error || "Failed to add product");
			}
		} catch (error) {
			console.error("Error adding product:", error);
			message.error("Error adding product");
		} finally {
			setLoading(false);
		}
	};

	const CountryPrices = ({ prices }) => {
		const [expanded, setExpanded] = React.useState(false);
		const visiblePrices = expanded ? prices : prices.slice(0, 3);

		return (
			<Space direction="vertical">
				{visiblePrices.map((price, index) => {
					const country = countries.find((c) => c.id === price.country_id);
					return (
						<div key={index}>
							{country
								? `${country.name}: ${country.currency} ${price.price}`
								: `${price.country_id}: ${price.price}`}
						</div>
					);
				})}
				{prices.length > 3 && (
					<a onClick={() => setExpanded(!expanded)}>
						{expanded ? "View Less" : "View More"}
					</a>
				)}
			</Space>
		);
	};

	const columns = [
		{
			title: "Image",
			dataIndex: "images",
			key: "images",
			render: (images) =>
				images.length > 0 ? (
					<img
						src={images[0].thumbUrl || images[0]}
						alt="Variant"
						style={{ width: 50, height: 50 }}
					/>
				) : (
					"No image"
				),
		},
		{ title: "Size", dataIndex: "size", key: "size" },
		{
			title: "Color",
			dataIndex: "color",
			key: "color",
			render: (colorId) =>
				colors.find((c) => c.id === colorId)?.name || colorId,
		},
		{ title: "Stock", dataIndex: "stock_quantity", key: "stock_quantity" },
		{ title: "Price", dataIndex: "price", key: "price" },
		{
			title: "Country Prices",
			dataIndex: "prices",
			key: "prices",
			render: (prices) => <CountryPrices prices={prices} />,
		},
		{
			title: "Actions",
			key: "actions",
			render: (_, record, index) => (
				<Space>
					<Button
						icon={<EditOutlined />}
						onClick={() => handleEditVariant(index)}>
						Edit
					</Button>
					<Popconfirm
						title="Are you sure you want to delete this variant?"
						onConfirm={() => handleDeleteVariant(index)}
						okText="Yes"
						cancelText="No">
						<Button icon={<DeleteOutlined />} danger>
							Delete
						</Button>
					</Popconfirm>
				</Space>
			),
		},
	];

	const expandedRowRender = (record) => (
		<Space size="middle" wrap>
			{record.images.map((image, index) => (
				<img
					key={index}
					src={image.thumbUrl || image}
					alt={`Variant ${index + 1}`}
					style={{ width: 100, height: 100 }}
				/>
			))}
		</Space>
	);

	const [commonPrice, setCommonPrice] = useState(0);
	const [currency, setCurrency] = useState(currentCountry.currency);
	const [exchangeRates, setExchangeRates] = useState({});

	const fetchExchangeRates = async () => {
		try {
			const response = await fetch(
				`https://api.exchangerate-api.com/v4/latest/${currency}`
			);
			const data = await response.json();
			setExchangeRates(data.rates);
		} catch (error) {
			console.error("Error fetching exchange rates:", error);
		}
	};

	useEffect(() => {
		fetchExchangeRates();
	}, [currency]);

	useEffect(() => {
		setNewVariant((prev) => ({
			...prev,
			prices: countries.map((country) => {
				const convertedPrice =
					commonPrice * (exchangeRates[country.currency] || 1);
				return {
					country_id: country.id,
					price: convertedPrice,
					discount:
						prev.prices.find((p) => p.country_id === country.id)?.discount || 0,
					discount_type:
						prev.prices.find((p) => p.country_id === country.id)
							?.discount_type || "Amount",
				};
			}),
		}));
	}, [commonPrice, exchangeRates]);

	const handleVariantVideoUpload = (info) => {
		setNewVariant({
			...newVariant,
			videos: info.fileList,
		});

		if (info.file.status === "done") {
			message.success(`${info.file.name} uploaded successfully.`);
		} else if (info.file.status === "error") {
			message.error(`${info.file.name} upload failed.`);
		}
	};

	const [addColorForm, setAddColorForm] = useState(false);
	const [newColor, setNewColor] = useState("");

	const handleAddcolor = async () => {
		const response = await fetch(`${apiurl}/colors/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				color: newColor,
			}),
		});
		const data = await response.json();
		if (response.ok) {
			setAddColorForm(false);
			setNewColor("");
			fetchData("/colors", setColors);
			message.success(data.message);
		} else {
			message.error(data.error);
		}
	};

	const handleSGUpload = ({ fileList }) => {
		setProductDetails((prev) => ({
			...prev,
			sizeGuide: fileList,
		}));
	};

	return (
		<Main>
			<div className="add-product-main">
				<div className="general-info">
					<h3>General Information</h3>
					<Form layout="vertical" className="general-info-form">
						<Form.Item label="Product Name" required className="form-item">
							<Input
								name="name"
								value={productDetails.name}
								onChange={handleInputChange}
								className="form-input"
							/>
						</Form.Item>
						<div className="form-item-group">
							<Form.Item label="Main Category" required className="form-item">
								<Select
									value={productDetails.mainCategory}
									onChange={(value) => {
										handleSelectChange(value, "mainCategory");
										handleSelectChange(null, "category");
										handleSelectChange(null, "subcategory");
										const filtered = categories.filter(
											(cat) => cat.maincategory.id === value
										);
										setFilteredCategories(filtered);
									}}
									loading={loading}
									className="form-select">
									{mainCategories.map((cat) => (
										<Option key={cat.id} value={cat.id} className="form-option">
											{cat.name}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label="Category" required className="form-item">
								<Select
									value={productDetails.category}
									onChange={(value) => {
										handleSelectChange(value, "category");
										handleSelectChange(null, "subcategory");
										const filtered = subcategories.filter(
											(sub) => sub.category.id === value
										);
										setFilteredSubcategories(filtered);
									}}
									loading={loading}
									className="form-select">
									{filteredCategories.map((cat) => (
										<Option key={cat.id} value={cat.id} className="form-option">
											{cat.name}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label="Subcategory" required className="form-item">
								<Select
									value={productDetails.subcategory}
									onChange={(value) => handleSelectChange(value, "subcategory")}
									loading={loading}
									className="form-select">
									{filteredSubcategories.map((cat) => (
										<Option key={cat.id} value={cat.id} className="form-option">
											{cat.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<Form.Item label="Description" required className="form-item">
							<TextArea
								name="description"
								value={productDetails.description}
								onChange={handleInputChange}
								className="form-textarea"
							/>
						</Form.Item>
						<Form.Item label="Size Guide" required className="form-item">
							<Upload
								listType="picture-card"
								fileList={productDetails.sizeGuide}
								maxCount={1}
								onChange={handleSGUpload}
								beforeUpload={() => false}>
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							</Upload>
						</Form.Item>
					</Form>
				</div>

				<div className="variants-table">
					<h3>Variants</h3>
					<Table
						dataSource={productDetails.variants}
						columns={columns}
						rowKey={(record, index) => index}
						expandable={{
							expandedRowRender: expandedRowRender,
							rowExpandable: (record) => record.images.length > 0,
						}}
					/>
					<Button
						icon={<PlusOutlined />}
						onClick={() => {
							setEditingVariant(null);
							setIsModalVisible(true);
						}}
						className="add-variant-btn">
						Add Variant
					</Button>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={loading}
						disabled={productDetails.variants.length === 0}
						className="submit-btn">
						Submit
					</Button>
				</div>

				<Modal
					title={editingVariant !== null ? "Edit Variant" : "Add Variant"}
					open={isModalVisible}
					onOk={handleAddVariant}
					className="varient-modal"
					onCancel={() => {
						setIsModalVisible(false);
						setEditingVariant(null);
					}}>
					<Form layout="vertical" className="variant-form">
						<div className="form-item-group">
							<Form.Item label="Size" className="form-item">
								<Input
									value={newVariant.size}
									onChange={(e) =>
										setNewVariant((prev) => ({ ...prev, size: e.target.value }))
									}
									className="form-input"
								/>
							</Form.Item>
							<Form.Item label="Color" className="form-item">
								<Select
									value={newVariant.color}
									onChange={(value) =>
										setNewVariant((prev) => ({ ...prev, color: value }))
									}
									className="form-select">
									{colors.map((color) => (
										<Option
											key={color.id}
											value={color.id}
											className="form-option">
											{color.name}
										</Option>
									))}
								</Select>
								<Button
									onClick={() => setAddColorForm(true)}
									type="text"
									className="add-col-btn">
									+ Add New Color
								</Button>
							</Form.Item>
							<Form.Item label="Stock Quantity" required className="form-item">
								<InputNumber
									name="stock_quantity"
									value={newVariant.stock_quantity}
									onChange={(value) =>
										setNewVariant((prev) => ({
											...prev,
											stock_quantity: value,
										}))
									}
									className="form-input-number"
								/>
							</Form.Item>
						</div>
						<p className="pd-note">
							Please Note that the dimensions are for shipping purpose
						</p>

						<div className="form-item-group">
							<Form.Item
								label="Weight(in grams)"
								required
								className="form-item">
								<InputNumber
									name="weight"
									value={newVariant.weight}
									onChange={(value) =>
										setNewVariant((prev) => ({
											...prev,
											weight: value,
										}))
									}
									className="form-input-number"
								/>
							</Form.Item>
							<Form.Item label="length(in cm)" required className="form-item">
								<InputNumber
									name="length"
									value={newVariant.length}
									onChange={(value) =>
										setNewVariant((prev) => ({
											...prev,
											length: value,
										}))
									}
									className="form-input-number"
								/>
							</Form.Item>
							<Form.Item label="Width (in cm)" required className="form-item">
								<InputNumber
									name="width"
									value={newVariant.width}
									onChange={(value) =>
										setNewVariant((prev) => ({
											...prev,
											width: value,
										}))
									}
									className="form-input-number"
								/>
							</Form.Item>
							<Form.Item label="Height (in cm)" required className="form-item">
								<InputNumber
									name="height"
									value={newVariant.height}
									onChange={(value) =>
										setNewVariant((prev) => ({
											...prev,
											height: value,
										}))
									}
									className="form-input-number"
								/>
							</Form.Item>
						</div>
						<Form.Item label="Variant Video" className="form-item">
							<Upload
								listType="picture-card"
								fileList={newVariant.videos}
								onChange={handleVariantVideoUpload}
								accept="video/*"
								maxCount={1}
								beforeUpload={() => false}>
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							</Upload>
						</Form.Item>

						<Form.Item label="Variant Images" required className="form-item">
							<Upload
								listType="picture-card"
								fileList={newVariant.images}
								accept="image/*"
								maxCount={5}
								onChange={handleVariantImageUpload}
								beforeUpload={() => false}>
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							</Upload>
						</Form.Item>

						<Form.Item label="Common Price" required className="form-item">
							<Space direction="horizontal">
								<InputNumber
									value={commonPrice}
									className="form-input-number"
									onChange={(value) => setCommonPrice(value)}
								/>
								<Select
									value={currency}
									onChange={(value) => setCurrency(value)}>
									{[
										...new Set(countries.map((country) => country.currency)),
									].map((uniqueCurrency) => (
										<Select.Option key={uniqueCurrency} value={uniqueCurrency}>
											{uniqueCurrency}
										</Select.Option>
									))}
								</Select>
							</Space>
						</Form.Item>

						<Form.Item label="Prices" required className="form-item">
							<Space direction="vertical" style={{ width: "100%" }}>
								{countries.map((country) => (
									<div key={country.id} className="country-prices">
										<label>
											{country.name} ({country.currency})
										</label>
										<div className="country-price-inps">
											<div>
												<lable>Price</lable>
												<InputNumber
													value={
														newVariant.prices.find(
															(p) => p.country_id === country.id
														)?.price
													}
													className="form-input-number"
													onChange={(value) =>
														setNewVariant((prev) => ({
															...prev,
															prices: [
																...prev.prices.filter(
																	(p) => p.country_id !== country.id
																),
																{
																	country_id: country.id,
																	price: value,
																	discount:
																		prev.prices.find(
																			(p) => p.country_id === country.id
																		)?.discount || 0,
																	discount_type:
																		prev.prices.find(
																			(p) => p.country_id === country.id
																		)?.discount_type || "Amount",
																},
															],
														}))
													}
												/>
											</div>
											<div>
												<lable>Discount Type</lable>
												<Select
													value={
														newVariant.prices.find(
															(p) => p.country_id === country.id
														)?.discount_type || "Amount"
													}
													onChange={(value) =>
														setNewVariant((prev) => ({
															...prev,
															prices: [
																...prev.prices.filter(
																	(p) => p.country_id !== country.id
																),
																{
																	country_id: country.id,
																	price:
																		prev.prices.find(
																			(p) => p.country_id === country.id
																		)?.price || 0,
																	discount:
																		prev.prices.find(
																			(p) => p.country_id === country.id
																		)?.discount || 0,
																	discount_type: value,
																},
															],
														}))
													}>
													<option value="Amount">Amount</option>
													<option value="Percentage">Percentage</option>
												</Select>
											</div>
											<div>
												<lable>Discount Value</lable>
												<InputNumber
													value={
														newVariant.prices.find(
															(p) => p.country_id === country.id
														)?.discount
													}
													className="form-input-number"
													onChange={(value) =>
														setNewVariant((prev) => ({
															...prev,
															prices: [
																...prev.prices.filter(
																	(p) => p.country_id !== country.id
																),
																{
																	country_id: country.id,
																	price:
																		prev.prices.find(
																			(p) => p.country_id === country.id
																		)?.price || 0,
																	discount: value,
																	discount_type:
																		prev.prices.find(
																			(p) => p.country_id === country.id
																		)?.discount_type || "Amount",
																},
															],
														}))
													}
												/>
											</div>
										</div>
									</div>
								))}
							</Space>
						</Form.Item>
					</Form>
				</Modal>
			</div>

			<Modal
				title={"Add New Color"}
				open={addColorForm}
				onClose={() => setAddColorForm(false)}
				onCancel={() => setAddColorForm(false)}
				destroyOnClose
				className="add-new-color-modal"
				footer={""}>
				<Input value={newColor} onChange={(e) => setNewColor(e.target.value)} />
				<Button onClick={handleAddcolor}>Submit</Button>
			</Modal>
		</Main>
	);
};

export default AddProductForm;
