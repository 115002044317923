import React from "react";
import justForYouImage from "./../../images/Contact/justForYou.png";
import Home from "./../../images/Contact/home.svg";
import Right from "./../../images/Contact/right.svg";
import Main from "../user/Layout";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { Row, Col, Card } from "antd";
import {
	MailOutlined,
	EnvironmentOutlined,
	PhoneOutlined,
} from "@ant-design/icons";
import Shield from "./../../images/Contact/Shield.png";
import ContactImg from "./../../images/Contact/Contact.png";
import Return from "./../../images/Contact/Return.png";
import Truck from "./../../images/Contact/Truck.png";
import "./contact.css";
import countries from "../utils/Countries";

const { Option } = Select;
const Contact = () => {
	const onFinish = (values) => {
		console.log("Form values: ", values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Form failed: ", errorInfo);
	};
	const data = [
		{
			icon: <MailOutlined style={{ fontSize: 30, color: "#ff0077" }} />,
			title: "Email",
			description: "Our friendly team is here to help.",
			detail: "info@sheagle.com",
		},
		{
			icon: <EnvironmentOutlined style={{ fontSize: 30, color: "#ff0077" }} />,
			title: "Office",
			description: "Come say hello at our office HQ.",
			detail:
				"SHEAGLE TRADING -FZCO, Premisis No-DFO-IFZA,Building Name: IFZA Properties,Area Name : Dubai Silicon Oasis,United Arab Emirates",
		},
		{
			icon: <PhoneOutlined style={{ fontSize: 30, color: "#ff0077" }} />,
			title: "Phone",
			description: "Mon-Fri from 9am to 6pm.",
			detail: "UAE : +971556697106, India :+91 7396631112 ",
		},
	];
	const features = [
		{
			icon: <img src={Truck} alt="Free Delivery" />,
			title: "Free Shipping",
			description: "Free shipping on all US orders or orders above $200.",
		},
		{
			icon: <img src={Shield} alt="Payment Assurance" />,
			title: "Payment Secure",
			description: "We ensure secure payment with PEV.",
		},
		{
			icon: <img src={Return} alt="7 Day Return" />,
			title: "7 Days Return",
			description: "Simply return it within 7 days for an exchange.",
		},
		{
			icon: <img src={ContactImg} alt="24/7 Support" />,
			title: "Support 24/7",
			description: "Contact us 24 hours a day, 7 days a week.",
		},
	];
	return (
		<Main>
			<div className="contactSection">
				<div className="backToHome">
					<img src={Home} alt="Home" />
					<img src={Right} alt="Right" />
					<div className="smallTxt">Contact Us</div>
				</div>
				<div className="contactForm ">
					<div className="form">
						<div className="heading">Contact Us</div>
						<div className="txt">
							Make your best moments more stylish with our latest designs of
							clothing.
						</div>
						<div className="formContent">
							<Form
								layout="vertical"
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}>
								<div className="name">
									<Form.Item
										name="firstName"
										label="First name"
										rules={[
											{
												required: true,
												message: "Please input your first name!",
											},
										]}
										style={{ fontWeight: "500" }}>
										<Input placeholder="First name" />
									</Form.Item>

									<Form.Item
										name="lastName"
										label="Last name"
										rules={[
											{
												required: true,
												message: "Please input your last name!",
											},
										]}
										style={{ fontWeight: "500" }}>
										<Input placeholder="Last name" />
									</Form.Item>
								</div>

								<Form.Item
									name="email"
									label="Email"
									rules={[
										{ required: true, message: "Please input your email!" },
										{ type: "email", message: "Please enter a valid email!" },
									]}
									style={{ fontWeight: "500" }}>
									<Input placeholder="you@company.com" />
								</Form.Item>

								<Form.Item
									name="phoneNumber"
									label="Phone number"
									rules={[
										{
											required: true,
											message: "Please input your phone number!",
										},
									]}
									style={{ fontWeight: "500" }}>
									<Input
										addonBefore={
											<Form.Item name="countryCode" noStyle>
												<Select defaultValue="US">
													{countries.map((country, index) => {
														return (
															<Select.Option key={index} value={country.code}>
																{country.name}
															</Select.Option>
														);
													})}
												</Select>
											</Form.Item>
										}
									/>
								</Form.Item>

								<Form.Item
									name="message"
									label="Message"
									style={{ fontWeight: "500" }}>
									<Input.TextArea
										rows={4}
										placeholder="Write your message..."
									/>
								</Form.Item>

								<Form.Item
									name="agreement"
									valuePropName="checked"
									rules={[
										{
											validator: (_, value) =>
												value
													? Promise.resolve()
													: Promise.reject("You must agree to continue!"),
										},
									]}>
									<Checkbox>
										You agree to our friendly{" "}
										<a href="/privacy-policy">privacy policy</a>
									</Checkbox>
								</Form.Item>

								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										style={{
											backgroundColor: "#ff0077",
											borderColor: "#ff0077",
										}}
										block>
										Send message
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
					<div className="justForYouImage">
						<img src={justForYouImage} alt="Just For you image" />
					</div>
				</div>
			</div>
			<div className="contact-container">
				<Row gutter={[24, 24]} justify="center">
					{data.map((item, index) => (
						<Col key={index} xs={24} sm={12} md={8}>
							<div className="contact-card">
								<div className="contact-icon-container">{item.icon}</div>
								<h3 className="contact-title">{item.title}</h3>
								<p className="contact-description">{item.description}</p>
								<p className="contact-detail">{item.detail}</p>
							</div>
						</Col>
					))}
				</Row>
			</div>
			<div className="features-container">
				<Row gutter={[24, 24]} justify="center">
					{features.map((feature, index) => (
						<Col key={index} xs={24} sm={12} md={6}>
							<div className="feature-card">
								<div className="feature-icon-container">{feature.icon}</div>
								<h3 className="feature-title">{feature.title}</h3>
								<p className="feature-description">{feature.description}</p>
							</div>
						</Col>
					))}
				</Row>
			</div>
		</Main>
	);
};

export default Contact;
