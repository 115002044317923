import React, { useState, useEffect, useCallback } from "react";
import {
	Typography,
	Checkbox,
	Button,
	Slider,
	List,
	message,
	Select,
	Breadcrumb,
	Drawer,
} from "antd";
import { useAuth } from "../utils/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Products.css";
import debounce from "lodash.debounce";
import Main from "./Layout";
import ProductCard from "./Productcard";
import prodimgbanner from "./../../images/Products/banner.png";
import { FilterOutlined, HomeOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const debouncedUpdateURL = debounce((callback) => {
	callback();
}, 500);

const ProductGallery = () => {
	const { apiurl, currentCountry } = useAuth();
	const [products, setProducts] = useState([]);
	const [mainCategories, setMainCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [colors, setColors] = useState([]);
	const [vendors, setVendors] = useState([]);

	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedMainCategories, setSelectedMainCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedSubCategories, setSelectedSubCategories] = useState([]);
	const [selectedColors, setSelectedColors] = useState([]);
	const [selectedVendors, setSelectedVendors] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [priceRange, setPriceRange] = useState([0, 100000]);
	const [sortOption, setSortOption] = useState(null);
	const [loading, setLoading] = useState(false);

	const [showAllCategories, setShowAllCategories] = useState(false);
	const [showAllSubCategories, setShowAllSubCategories] = useState(false);
	const [showAllColors, setShowAllColors] = useState(false);
	const [filtersModal, setFiltersModal] = useState(false);
	const [showAllVendors, setShowAllVendors] = useState(false);

	const { token } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(
		(currentParams) => {
			const queryParams = new URLSearchParams(location.search);
			const mainCategories =
				queryParams.get("mainCategories")?.split(",") || [];
			const categories = queryParams.get("categories")?.split(",") || [];
			const subcategories = queryParams.get("subcategories")?.split(",") || [];

			const colors = queryParams.get("colors")?.split(",") || [];
			const vendors = queryParams.get("vendors")?.split(",") || [];
			const search = queryParams.get("search") || "";

			const sort = queryParams.get("sort") || null;

			setSelectedMainCategories(mainCategories.map(Number));
			setSelectedCategories(categories.map(Number));
			setSelectedSubCategories(subcategories.map(Number));

			setSelectedColors(colors.map(Number));
			setSelectedVendors(vendors.map(Number));
			setSearchQuery(search);
			setSortOption(sort);

			const newParams = queryParams.toString();

			if (currentParams !== newParams) {
				navigate(`?${newParams}`, { replace: true });
			}

			fetchInitialData();
		},
		[location.search]
	);

	useEffect(() => {
		filterProducts();
	}, [
		selectedMainCategories,
		selectedCategories,
		selectedSubCategories,
		selectedColors,
		selectedVendors,
		searchQuery,
		priceRange,
		sortOption,
		products,
	]);

	const fetchInitialData = async () => {
		setLoading(true);
		try {
			const [
				productsRes,
				mainCategoriesRes,
				categoriesRes,
				subCategoriesRes,
				colorsRes,
				vendorsRes,
			] = await Promise.all([
				fetch(`${apiurl}/products`).then((res) => res.json()),
				fetch(`${apiurl}/maincategories`).then((res) => res.json()),
				fetch(`${apiurl}/categories`).then((res) => res.json()),
				fetch(`${apiurl}/subcategories`).then((res) => res.json()),
				fetch(`${apiurl}/colors`).then((res) => res.json()),
				fetch(`${apiurl}/filtervendors`).then((res) => res.json()),
			]);

			setProducts(productsRes);
			setFilteredProducts(productsRes);
			setMainCategories(mainCategoriesRes);
			setCategories(categoriesRes);
			setSubCategories(subCategoriesRes);
			setColors(colorsRes);
			setVendors(vendorsRes);
		} catch (error) {
			console.error("Failed to fetch data:", error);
		} finally {
			setLoading(false);
		}
	};

	const updateURL = useCallback(() => {
		const queryParams = new URLSearchParams();
		if (selectedMainCategories.length > 0)
			queryParams.set("mainCategories", selectedMainCategories.join(","));
		if (selectedCategories.length > 0)
			queryParams.set("categories", selectedCategories.join(","));
		if (selectedSubCategories.length > 0)
			queryParams.set("subcategories", selectedSubCategories.join(","));
		if (selectedColors.length > 0)
			queryParams.set("colors", selectedColors.join(","));
		if (selectedVendors.length > 0)
			queryParams.set("vendors", selectedVendors.join(","));
		if (searchQuery) queryParams.set("search", searchQuery);
		if (sortOption) queryParams.set("sort", sortOption);

		navigate(`?${queryParams.toString()}`, { replace: true });
	}, [
		selectedMainCategories,
		selectedCategories,
		selectedSubCategories,
		selectedColors,
		selectedVendors,
		searchQuery,
		sortOption,
	]);

	useEffect(() => {
		debouncedUpdateURL(updateURL);
		return () => {
			debouncedUpdateURL.cancel();
		};
	}, [
		selectedMainCategories,
		selectedCategories,
		selectedColors,
		selectedVendors,
		searchQuery,
		priceRange,
		sortOption,
		updateURL,
	]);

	const onPriceRangeChange = (value) => {
		setPriceRange(value);
	};
	const filterProducts = () => {
		let updatedProducts = [...products];

		if (selectedMainCategories.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedMainCategories.includes(product.category?.maincategory?.id)
			);
		}

		if (selectedCategories.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedCategories.includes(product.category?.id)
			);
		}

		if (selectedSubCategories.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedSubCategories.includes(product.sub_category?.id)
			);
		}

		if (selectedColors.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				product.product_varients.some((color) =>
					selectedColors.includes(color.color.id)
				)
			);
		}
		if (selectedVendors.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedVendors.includes(product.vendor?.id)
			);
		}

		if (searchQuery) {
			updatedProducts = updatedProducts.filter((product) =>
				product.name.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}

		updatedProducts = updatedProducts.filter((product) =>
			product.product_varients.some((variant) => {
				const countryPriceData = variant.country_specific_prices.find(
					(price) => price.country_name === currentCountry.country_name
				);

				const variantPrice =
					countryPriceData && countryPriceData.price !== undefined
						? parseFloat(countryPriceData.price)
						: parseFloat(variant.price);
				return (
					!isNaN(variantPrice) &&
					variantPrice >= priceRange[0] &&
					variantPrice <= priceRange[1]
				);
			})
		);

		if (sortOption) {
			switch (sortOption) {
				case "most_buyed":
					updatedProducts.sort((a, b) => b.total_num_buyed - a.total_num_buyed);
					break;
				case "most_wished":
					updatedProducts.sort(
						(a, b) => b.total_num_wishlisted - a.total_num_wishlisted
					);
					break;
				case "most_rated":
					updatedProducts.sort((a, b) => b.avg_rating - a.avg_rating);
					break;
				case "latest":
					updatedProducts.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);
					break;
				default:
					break;
			}
		}

		setFilteredProducts(updatedProducts);
	};

	const handleSortChange = (value) => {
		setSortOption(value);
	};

	const handleMainCategoryChange = (checkedValue, mainCategoryId) => {
		const updatedSelection = checkedValue
			? [...selectedMainCategories, mainCategoryId]
			: selectedMainCategories.filter((id) => id !== mainCategoryId);
		setSelectedMainCategories(updatedSelection);

		if (!checkedValue) {
			setSelectedCategories([]);
			setSelectedSubCategories([]);
		}
	};

	const renderFilterCheckboxes = (
		items,
		selectedItems,
		setSelectedItems,
		showAll,
		setShowAll
	) => {
		const displayedItems = showAll ? items : items.slice(0, 6);

		const handleChange = (checkedValue, itemId) => {
			const updatedSelection = checkedValue
				? [...selectedItems, itemId]
				: selectedItems.filter((id) => id !== itemId);
			setSelectedItems(updatedSelection);
		};

		return (
			<>
				<div>
					{displayedItems.map((item) => (
						<div>
							<Checkbox
								className="check-box"
								key={item.id}
								checked={selectedItems.includes(item.id)}
								onChange={(e) => handleChange(e.target.checked, item.id)}>
								{item.name || item.business_name}
							</Checkbox>
						</div>
					))}
				</div>
				{items.length > 6 && (
					<Button type="link" onClick={() => setShowAll(!showAll)}>
						{showAll ? "Show Less" : "Show More"}
					</Button>
				)}
			</>
		);
	};

	const toogleWishlist = async (prodid, status) => {
		if (!token) {
			message.warning("Please login to add to wishlist");
			return;
		}
		let method = status === true ? "POST" : "DELETE";
		const response = await fetch(`${apiurl}/wishlist/`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			method: method,
			body: JSON.stringify({ item_id: prodid }),
		});
		if (response.ok) {
			const data = await response.json();
			fetchInitialData();
			message.success(data.message);
		} else {
			console.log("Error");
		}
	};

	const addtocart = async (prodid) => {
		if (!token) {
			message.warning("Please login to add to cart");
			return;
		}
		const response = await fetch(`${apiurl}/cart/`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			method: "POST",
			body: JSON.stringify({ item_id: prodid }),
		});
		if (response.ok) {
			fetchInitialData();
			message.success("Added to cart");
		} else {
			console.log("Error");
		}
	};

	return (
		<Main>
			<div>
				{loading ? (
					<>Loading</>
				) : (
					<>
						<div className="products-image-banner">
							{/* <img src={prodimgbanner} alt="sheagle-products"></img> */}
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to="/">
										<HomeOutlined /> Home
									</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>Products</Breadcrumb.Item>
							</Breadcrumb>
							<button onClick={() => setFiltersModal(true)}>
								<FilterOutlined /> Filter
							</button>
						</div>
						<div className="products-main">
							<div className="filters">
								<div className="sort-options">
									<Title level={5}>Sort By</Title>
									<Select
										defaultValue={sortOption}
										style={{ width: "100%" }}
										onChange={(value) => setSortOption(value)}>
										<Option value="most_buyed">Most Bought</Option>
										<Option value="most_wished">Most Wished</Option>
										<Option value="most_rated">Most Rated</Option>
										<Option value="latest">Latest</Option>
									</Select>
								</div>
								<div className="price">
									<Title level={5}>Price</Title>
									<p>
										<div>Range</div>
										<div>
											{priceRange[0]} - {priceRange[1]}
										</div>
									</p>
									<Slider
										range
										min={100}
										max={100000}
										defaultValue={priceRange}
										onChange={onPriceRangeChange}
									/>
								</div>
								<div className="main-categories">
									<Title level={5}>Main Categories</Title>
									<div>
										{mainCategories.map((mainCategory) => (
											<div key={mainCategory.id}>
												<Checkbox
													checked={selectedMainCategories.includes(
														mainCategory.id
													)}
													onChange={(e) =>
														handleMainCategoryChange(
															e.target.checked,
															mainCategory.id
														)
													}>
													{mainCategory.name}
												</Checkbox>
											</div>
										))}
									</div>
								</div>
								<div className="categories">
									{categories.filter((cat) =>
										selectedMainCategories.includes(cat.maincategory.id)
									).length > 0 && <Title level={5}>Categories</Title>}
									{renderFilterCheckboxes(
										categories.filter((cat) =>
											selectedMainCategories.includes(cat.maincategory.id)
										),
										selectedCategories,
										setSelectedCategories,
										showAllCategories,
										setShowAllCategories
									)}
								</div>
								<div className="sub-categories">
									{subCategories.filter((sub) =>
										selectedCategories.includes(sub.category.id)
									).length > 0 && <Title level={5}>Subcategories</Title>}
									{renderFilterCheckboxes(
										subCategories.filter((sub) =>
											selectedCategories.includes(sub.category.id)
										),
										selectedSubCategories,
										setSelectedSubCategories,
										showAllSubCategories,
										setShowAllSubCategories
									)}
								</div>
								<div className="colors">
									<Title level={5}>Colors</Title>
									{renderFilterCheckboxes(
										colors,
										selectedColors,
										setSelectedColors,
										showAllColors,
										setShowAllColors
									)}
								</div>
								<div className="vendors">
									<Title level={5}>Vendors</Title>
									{renderFilterCheckboxes(
										vendors,
										selectedVendors,
										setSelectedVendors,
										showAllVendors,
										setShowAllVendors
									)}
								</div>
							</div>

							<List
								itemLayout="vertical"
								pagination={{
									onChange: (page) => {},
									pageSize: 8,
									className: "prod-pg-class",
								}}
								className="products-list"
								dataSource={filteredProducts}
								renderItem={(product) => (
									<ProductCard
										addtocart={addtocart}
										product={product.product_varients[0]}
										toogleWishlist={toogleWishlist}
									/>
								)}
								locale={{
									emptyText: "No products found matching your filters.",
								}}
							/>
						</div>
					</>
				)}
			</div>
			<div className="products-filter-drawer">
				<Drawer
					title="Filter Products"
					placement="bottom"
					className="filters-drawer"
					onClose={() => setFiltersModal(false)}
					open={filtersModal}
					width={300}>
					<div className="mb-filters">
						<div className="sort-options">
							<Title level={5}>Sort By</Title>
							<Select
								defaultValue={sortOption}
								style={{ width: "100%" }}
								onChange={(value) => setSortOption(value)}>
								<Option value="most_buyed">Most Bought</Option>
								<Option value="most_wished">Most Wished</Option>
								<Option value="most_rated">Most Rated</Option>
								<Option value="latest">Latest</Option>
							</Select>
						</div>
						<div className="price">
							<Title level={5}>Price</Title>
							<p>
								<div>Range</div>
								<div>
									{priceRange[0]} - {priceRange[1]}
								</div>
							</p>
							<Slider
								range
								min={100}
								max={100000}
								defaultValue={priceRange}
								onChange={onPriceRangeChange}
							/>
						</div>
						<div className="main-categories">
							<Title level={5}>Main Categories</Title>
							<div>
								{mainCategories.map((mainCategory) => (
									<div key={mainCategory.id}>
										<Checkbox
											checked={selectedMainCategories.includes(mainCategory.id)}
											onChange={(e) =>
												handleMainCategoryChange(
													e.target.checked,
													mainCategory.id
												)
											}>
											{mainCategory.name}
										</Checkbox>
									</div>
								))}
							</div>
						</div>
						<div className="categories">
							{categories.filter((cat) =>
								selectedMainCategories.includes(cat.maincategory.id)
							).length > 0 && <Title level={5}>Categories</Title>}
							{renderFilterCheckboxes(
								categories.filter((cat) =>
									selectedMainCategories.includes(cat.maincategory.id)
								),
								selectedCategories,
								setSelectedCategories,
								showAllCategories,
								setShowAllCategories
							)}
						</div>
						<div className="sub-categories">
							{subCategories.filter((sub) =>
								selectedCategories.includes(sub.category.id)
							).length > 0 && <Title level={5}>Subcategories</Title>}
							{renderFilterCheckboxes(
								subCategories.filter((sub) =>
									selectedCategories.includes(sub.category.id)
								),
								selectedSubCategories,
								setSelectedSubCategories,
								showAllSubCategories,
								setShowAllSubCategories
							)}
						</div>
						<div className="colors">
							<Title level={5}>Colors</Title>
							{renderFilterCheckboxes(
								colors,
								selectedColors,
								setSelectedColors,
								showAllColors,
								setShowAllColors
							)}
						</div>
						<div className="vendors">
							<Title level={5}>Vendors</Title>
							{renderFilterCheckboxes(
								vendors,
								selectedVendors,
								setSelectedVendors,
								showAllVendors,
								setShowAllVendors
							)}
						</div>
					</div>
				</Drawer>
			</div>
		</Main>
	);
};

export default ProductGallery;
