import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main from '../Layout';
import { useAuth } from '../../utils/useAuth';
import { Table, Button } from 'antd';
import './AllBlogs.css'

const AllBlogs = () => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState([]);

    const fetchBlogs = async () => {
        try {
            const response = await fetch(`${apiurl}/admin/get_blogs/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                console.error("Unable to fetch the data");
            } else {
                const result = await response.json();
                setData(result);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (token) {
            fetchBlogs();
        }
    }, [token]);

    const handleAcceptOrReject = async (blog, approval_status) => {
        try {
            const response = await fetch(`${apiurl}/admin/approve_blog_post/?blog_id=${blog.id}&is_approved=${approval_status}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.error) {
                console.error(data.error);
            } else {
                console.log(data.message);
                fetchBlogs();
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (data) {
            console.log(data)
        }
    })

    const columns = [
        {
            title: "Blog Title",
            dataIndex: "title",
            key: "title",
            render: (text, record) => (
                <Link to={`/admin/specificblog/${record.id}`} style={{ color: 'black' }} >
                    {text}
                </Link>
            )
        },
        {
            title: "Created By",
            dataIndex: "user",
            key: "user",
            render: (user) => user?.username || "Unknown"
        },
        {
            title: "Created At",
            dataIndex: "updated_at",
            key: 'updated_at'
        },
        {
            title: "Approval Status",
            dataIndex: "approval_status",
            key: "approval_status",
            render: (status, record) => (
                record.is_approved ? (
                    "Approved"
                ) : (
                    <>
                        <Button type="primary" onClick={() => handleAcceptOrReject(record, true)}>Accept</Button>
                    </>
                )
            )
        }
    ];

    return (
        <Main>
            {data &&

                <Table columns={columns} dataSource={data} rowKey="id" />
            }
        </Main>
    );
};

export default AllBlogs;
