import React, { useEffect, useState } from "react";
import Main from "./Layout";
import icon from "./../../images/Homepage/homebanner.png";
import kids from "./../../images/Homepage/kids.png";
import men from "./../../images/Homepage/men.png";
import women from "./../../images/Homepage/women.png";
import beauty from "./../../images/Homepage/beauty.png";
import banner1 from "./../../images/Homepage/Banners/Banners with text (1).jpg";
import banner2 from "./../../images/Homepage/Banners/Banners with text (2).jpg";
import banner3 from "./../../images/Homepage/Banners/Banners with text (3).jpg";
import banner4 from "./../../images/Homepage/Banners/Banners with text (4).jpg";
import banner5 from "./../../images/Homepage/Banners/Banners with text (5).jpg";
import banner6 from "./../../images/Homepage/Banners/Banners with text (6).jpg";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import ProductCard from "./Productcard";
import { useAuth } from "../utils/useAuth";
import Slider from "react-slick";
import { Carousel, message } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const Home = () => {
	const [topProducts, setTopProducts] = useState([]);
	const [bestSellers, setBestSellers] = useState([]);
	const [brands, setBrands] = useState([]);
	const [brandCard, setBrandCard] = useState(0);
	const [cats, setCats] = useState([]);
	const { apiurl, token } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		fetchMostWished();
		fetchBestSellers();
		fetchBrands();
		fetchCats();
	}, [token]);

	const fetchMostWished = async () => {
		let headers = {
			"Content-Type": "application/json",
		};

		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}
		const response = await fetch(`${apiurl}/products/most-wished/`, {
			headers: headers,
		});
		const data = await response.json();
		setTopProducts(data);
	};

	const toogleWishlist = async (prodid, status) => {
		if (!token) {
			message.warning("Please login to add to wishlist");
			return;
		}
		let method = status === true ? "POST" : "DELETE";
		const response = await fetch(`${apiurl}/wishlist/`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			method: method,
			body: JSON.stringify({ item_id: prodid }),
		});
		if (response.ok) {
			const data = await response.json();
			fetchMostWished();
			fetchBestSellers();
			message.success(data.message);
		} else {
			console.log("Error");
		}
	};

	const addtocart = async (prodid) => {
		if (!token) {
			message.warning("Please login to add to cart");
			return;
		}
		const response = await fetch(`${apiurl}/cart/`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			method: "POST",
			body: JSON.stringify({ item_id: prodid }),
		});
		if (response.ok) {
			fetchMostWished();
			fetchBestSellers();
			message.success("Added to cart");
		} else {
			console.log("Error");
		}
	};

	const sliderSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: Math.floor(window.innerWidth / 310),
		slidesToScroll: 1,
		arrows: true,
	};

	const fetchBestSellers = async () => {
		let headers = {
			"Content-Type": "application/json",
		};

		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}

		try {
			const response = await fetch(`${apiurl}/products/best-sellers/`, {
				headers: headers,
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status}`);
			}

			const data = await response.json();
			setBestSellers(data);
		} catch (error) {
			console.error("Failed to fetch best sellers:", error);
		}
	};

	const fetchBrands = async () => {
		const response = await fetch(`${apiurl}/brands`);
		if (response.ok) {
			const data = await response.json();
			setBrands(data);
		}
	};

	const fetchCats = async () => {
		const response = await fetch(`${apiurl}/homecategories/`);
		if (response.ok) {
			const data = await response.json();
			setCats(data);
		}
	};

	const handlescroll = (clicked) => {
		setBrands((prevBrands) => {
			const newBrands = [...prevBrands];

			if (clicked === "right") {
				const firstItem = newBrands.shift();
				newBrands.push(firstItem);
			} else {
				const lastItem = newBrands.pop();
				newBrands.unshift(lastItem);
			}

			return newBrands;
		});
	};

	return (
		<Main>
			<div className="home-main">
				<div className="offer-tag">FREE SHIPPING ON OFFERS ABOVE 2500</div>
				<Carousel autoplay={true} effect="scrollx" infinite>
					<div className="home-banner">
						<img src={banner1} alt="SHEagle Trading" />
						<div className="bannercontent">
							<span>NEW IN</span>STUDIO COLLECTION
							{}
						</div>
					</div>
					<div className="home-banner">
						<img src={banner2} alt="SHEagle Trading" />
						<div className="bannercontent">
							<span>NEW IN</span>STUDIO COLLECTION
							{}
						</div>
					</div>
					<div className="home-banner">
						<img src={banner3} alt="SHEagle Trading" />
						<div className="bannercontent">
							<span>NEW IN</span>STUDIO COLLECTION
							{}
						</div>
					</div>
					<div className="home-banner">
						<img src={banner4} alt="SHEagle Trading" />
						<div className="bannercontent">
							<span>NEW IN</span>STUDIO COLLECTION
							{}
						</div>
					</div>
					<div className="home-banner">
						<img src={banner5} alt="SHEagle Trading" />
						<div className="bannercontent">
							<span>NEW IN</span>STUDIO COLLECTION
							{}
						</div>
					</div>
					<div className="home-banner">
						<img src={banner6} alt="SHEagle Trading" />
						<div className="bannercontent">
							<span>NEW IN</span>STUDIO COLLECTION
							{}
						</div>
					</div>
				</Carousel>
				<div className="offer-tag-large">Get 20% Off On First Order</div>

				<div className="brands-section">
					<h2>Popular Brands</h2>

					<div className="brands-btns-conatiner">
						<button
							onClick={() => handlescroll("left")}
							className="brands-grid-btns1">
							<ArrowLeftOutlined />
						</button>
						<div className="brands-grid">
							{brands.map((brand, index) => {
								const itemWidth = 160;
								const gap = 40;
								const totalItemWidth = itemWidth + gap;
								const visibleItems = Math.floor(
									window.innerWidth / totalItemWidth
								);

								let isHidden = index < brands.length - visibleItems;

								return (
									<div
										key={index}
										className={`brand-box ${
											brandCard === index ? "border-red" : ""
										}`}
										style={{ display: isHidden ? "none" : "block" }}>
										<img src={apiurl + brand.image} alt={brand.name} />
									</div>
								);
							})}
						</div>
						<button
							onClick={() => handlescroll("right")}
							className="brands-grid-btns2">
							<ArrowRightOutlined />
						</button>
					</div>
				</div>

				<div className="main-cats">
					<div className="cat">
						<Link to="/kids">
							<img src={kids} alt="kids"></img>
						</Link>
					</div>
					<div className="cat">
						<Link to="/women">
							<img src={women} alt="women"></img>
						</Link>
					</div>
					<div className="cat">
						<Link to="/men">
							<img src={men} alt="men"></img>
						</Link>
					</div>
					<div className="cat">
						<Link to="/beauty">
							<img src={beauty} alt="beauty"></img>
						</Link>
					</div>
				</div>

				<div className="home-banner-2">
					<img src={icon} alt="SHEagle Trading" />
					<div className="bannercontent">
						<span>Don't delay; buy today.</span>
						<button
							className="shpnow-btn"
							onClick={() => navigate("/products")}>
							Shop Now
						</button>
					</div>
				</div>
				<div className="topsellers">
					<h2>Best Sellers</h2>
					<Slider {...sliderSettings}>
						{bestSellers &&
							bestSellers.map((product, index) => (
								<div>
									{product && product.product_varients && (
										<ProductCard
											key={index}
											addtocart={addtocart}
											product={product.product_varients[0]}
											toogleWishlist={toogleWishlist}></ProductCard>
									)}
								</div>
							))}
					</Slider>
				</div>
				<div className="bycategories">
					<h2>Shop By Categories</h2>
					<div className="bycatlist">
						<div className="bycat-3">
							{cats.slice(0, 3).map((cat, index) => (
								<div className="bycat-item" key={index}>
									<Link to={`/products/${cat.navigationUrl}`}>
										<img
											src={apiurl + cat.image}
											alt={`Category-${cat.name}`}
										/>
										<div>{cat.name}</div>
									</Link>
								</div>
							))}
						</div>
						<div className="bycat-4">
							{cats.slice(3, 7).map((cat, index) => (
								<div className="bycat-item" key={index}>
									<Link to={`/products/${cat.navigationUrl}`}>
										<img
											src={apiurl + cat.image}
											alt={`Category-${cat.name}`}
										/>
										<div>{cat.name}</div>
									</Link>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="topsellers">
					<h2>Top Picks For You</h2>
					<Slider {...sliderSettings}>
						{topProducts &&
							topProducts.map((product, index) => (
								<div>
									{product && product.product_varients && (
										<ProductCard
											toogleWishlist={toogleWishlist}
											key={index}
											addtocart={addtocart}
											product={product.product_varients[0]}></ProductCard>
									)}
								</div>
							))}
					</Slider>
				</div>
				<br />
				<br />
				<br />
			</div>
		</Main>
	);
};

export default Home;
