import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Upload, message, Steps } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import { Link, useNavigate } from "react-router-dom";
import bannerimg from "./../../images/handshake.png";
import logo from "./../../images/Homepage/logo.svg";
import "./Signup.css";

const { Option } = Select;
const { Dragger } = Upload;
const { Step } = Steps;

const VendorSignup = () => {
	const { apiurl, handleLogin } = useAuth();
	const [loading, setLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [formData, setFormData] = useState({});

	const [form] = Form.useForm();

	const steps = [
		{
			content: (
				<>
					<Form.Item
						label="Sellers Contact Name"
						name="contact_name"
						rules={[{ required: true, message: "Contact name is required." }]}>
						<Input placeholder="Enter sellers contact name" />
					</Form.Item>
					<Form.Item
						label="Sellers Contact Number"
						name="contact_phone"
						rules={[
							{ required: true, message: "Contact number is required." },
						]}>
						<Input placeholder="Enter sellers contact number" />
					</Form.Item>
					<Form.Item
						label="Sellers Contact Email"
						name="contact_email"
						rules={[
							{ required: true, message: "Contact email is required." },
							{ type: "email", message: "Enter a valid email." },
						]}>
						<Input placeholder="Enter sellers contact email" />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: "Password is required." }]}>
						<Input.Password placeholder="Enter password" />
					</Form.Item>

					<Form.Item
						label="Confirm Password"
						name="cpassword"
						rules={[{ required: true, message: "Password is required." }]}>
						<Input.Password placeholder="Confirm password" />
					</Form.Item>
				</>
			),
		},
		{
			content: (
				<>
					<Form.Item
						label="Year of Establishment"
						name="year_of_establishment"
						rules={[{ required: true, message: "Year is required." }]}>
						<Input type="number" placeholder="Enter year" />
					</Form.Item>
					<Form.Item
						label="Business Type"
						name="business_type"
						rules={[{ required: true, message: "Business type is required." }]}>
						<Select placeholder="Select business type">
							<Option value="Sole Proprietorship">Sole Proprietorship</Option>
							<Option value="Partnership">Partnership</Option>
							<Option value="LLC">LLC</Option>
							<Option value="Corporation">Corporation</Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Business Name"
						name="business_name"
						rules={[{ required: true, message: "Business name is required." }]}>
						<Input placeholder="Enter business name" />
					</Form.Item>
					<Form.Item
						label="Business Address"
						name="business_address_line"
						rules={[
							{ required: true, message: "Business address is required." },
						]}>
						<Input.TextArea placeholder="Enter business address" rows={4} />
					</Form.Item>
					<Form.Item
						label="Business Address State"
						name="business_address_state"
						rules={[{ required: true, message: "State is required." }]}>
						<Input placeholder="Enter state" />
					</Form.Item>
					<Form.Item
						label="Business Address City"
						name="business_address_city"
						rules={[{ required: true, message: "City is required." }]}>
						<Input placeholder="Enter city" />
					</Form.Item>
					<Form.Item
						label="Business Address Pincode"
						name="business_address_pincode"
						rules={[{ required: true, message: "Pincode is required." }]}>
						<Input placeholder="Enter pincode" />
					</Form.Item>
					<Form.Item
						label="Business Address Country"
						name="business_address_country"
						rules={[{ required: true, message: "Country is required." }]}>
						<Input placeholder="Enter country" />
					</Form.Item>
					<Form.Item
						label="Tax Identification Number"
						name="tax_identification_number"
						rules={[
							{
								required: true,
								message: "Tax Identification Number is required.",
							},
						]}>
						<Input placeholder="Enter Tax Identification Number" />
					</Form.Item>
				</>
			),
		},
		{
			content: (
				<>
					<Form.Item
						label="Business Registration Certificate"
						name="business_registration_certificate"
						valuePropName="fileList"
						getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
						rules={[{ required: true, message: "Certificate is required." }]}>
						<Dragger
							beforeUpload={() => false}
							accept=".pdf,.jpg,.jpeg,.png"
							maxCount={1}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</Form.Item>
					<Form.Item
						label="Owner Identification"
						name="owner_identification"
						valuePropName="fileList"
						getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
						rules={[{ required: true, message: "Owner ID is required." }]}>
						<Dragger
							beforeUpload={() => false}
							accept=".pdf,.jpg,.jpeg,.png"
							maxCount={1}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</Form.Item>
				</>
			),
		},
		{
			content: (
				<>
					<Form.Item
						label="Bank Name"
						name="bank_name"
						rules={[{ required: true, message: "Bank name is required." }]}>
						<Input placeholder="Enter bank name" />
					</Form.Item>
					<Form.Item
						label="Branch"
						name="branch"
						rules={[{ required: true, message: "Branch is required." }]}>
						<Input placeholder="Enter branch" />
					</Form.Item>
					<Form.Item
						label="Bank Account Name"
						name="bank_account_name"
						rules={[
							{ required: true, message: "Bank account name is required." },
						]}>
						<Input placeholder="Enter bank account name" />
					</Form.Item>
					<Form.Item
						label="Account Number"
						name="account_number"
						rules={[
							{ required: true, message: "Account number is required." },
						]}>
						<Input placeholder="Enter account number" />
					</Form.Item>
					<Form.Item
						label="SWIFT Code"
						name="swift_code"
						rules={[{ required: true, message: "SWIFT code is required." }]}>
						<Input placeholder="Enter SWIFT code" />
					</Form.Item>
					<Form.Item
						label="IFSC Code"
						name="ifsc_code"
						rules={[{ required: true, message: "IFSC code is required." }]}>
						<Input placeholder="Enter IFSC code" />
					</Form.Item>
				</>
			),
		},
		{
			content: (
				<>
					<Form.Item
						label="Business Logo"
						name="logo"
						valuePropName="fileList"
						getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
						rules={[
							{ required: false, message: "Business logo is optional." },
						]}>
						<Dragger
							beforeUpload={() => false}
							accept=".jpg,.jpeg,.png"
							maxCount={1}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to upload your business logo
							</p>
						</Dragger>
					</Form.Item>
					<Form.Item label="Operating Hours" name="operating_hours">
						<Input placeholder="Enter operating hours" />
					</Form.Item>
					<Form.Item
						label="Product Categories"
						name="product_categories"
						rules={[{ required: true, message: "Select product categories." }]}>
						<Select mode="multiple" placeholder="Select categories">
							{categories.map((option) => {
								return (
									<Option key={option.id} value={option.id}>
										{option.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</>
			),
		},
	];

	const onFinish = async (values) => {
		const completeFormData = { ...formData, ...values };
		const formDataObject = new FormData();

		Object.entries(completeFormData).forEach(([key, value]) => {
			if (
				key === "business_registration_certificate" ||
				key === "owner_identification" ||
				key === "logo"
			) {
				if (Array.isArray(value)) {
					formDataObject.append(key, value[0]?.originFileObj);
				}
			} else if (key === "product_categories") {
				formDataObject.append(key, JSON.stringify(value));
			} else {
				formDataObject.append(key, value);
			}
		});

		setLoading(true);

		try {
			const response = await fetch(`${apiurl}/vendor/`, {
				method: "POST",
				body: formDataObject,
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Vendor signed up successfully!");
				handleLogin(data.access_token, data.role, data.countryCode);
				navigate("/vendor/home");
			} else {
				message.error(data.detail || "Error signing up vendor.");
			}
		} catch (error) {
			message.error("Something went wrong. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const onStepChange = async () => {
		try {
			const values = await form.validateFields();
			if (currentStep === 0) {
				if (values.password !== values.cpassword) {
					message.error("Passwords do not match");
					return;
				}
			}
			setFormData({ ...formData, ...values });
			setCurrentStep(currentStep + 1);
			form.setFieldsValue({ ...formData, ...values });
		} catch (error) {
			console.log("Validation failed:", error);
		}
	};

	const prev = () => {
		const currentFormData = form.getFieldsValue();
		setFormData({ ...formData, ...currentFormData });
		setCurrentStep((prev) => prev - 1);
		form.setFieldsValue({ ...formData, ...currentFormData });
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const fetchCategories = async () => {
		const response = await fetch(`${apiurl}/maincategories`);
		const data = await response.json();
		if (response.ok) {
			setCategories(data);
		} else {
			setCategories([]);
		}
	};

	return (
		<div className="vendor-sign-up-main">
			<div className="left-sec">
				<img src={bannerimg} alt="sheagle" className="banner"></img>
				<img src={logo} alt="sheagle" className="logo"></img>
				<div>
					<h2>
						Partnership for <br />
						Business Growth
					</h2>
					<p>Where Style Meets Shared Success</p>
				</div>
			</div>
			<div className="right-sec">
				<div className="steps-cont">
					<p>
						{currentStep} of {steps.length} are completed.{" "}
						<Link to="/">Go Home</Link>
					</p>
					<Steps current={currentStep} responsive={false}>
						{steps.map((item, index) => (
							<Step key={index} title={item.title} />
						))}
					</Steps>
				</div>
				<div className="top-terms-cont">
					<h3>Partner With Us</h3>
					<p>
						Fill in the data for Registration.It will take a couple of minutes.
						<span>
							By signing up you are confirming our terms and conditions.
						</span>
					</p>
				</div>
				<div className="form-cont">
					<Form
						defaultValue={formData}
						form={form}
						layout="vertical"
						onFinish={onFinish}>
						{steps[currentStep].content}
						<Form.Item>
							<div>
								{currentStep > 0 && (
									<Button className="prev-btn" onClick={prev}>
										Previous
									</Button>
								)}
								{currentStep < steps.length - 1 && (
									<Button type="primary" onClick={onStepChange}>
										Next
									</Button>
								)}
								{currentStep === steps.length - 1 && (
									<Button type="primary" htmlType="submit" loading={loading}>
										Submit
									</Button>
								)}
							</div>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default VendorSignup;
