import React, { useState, useEffect } from "react";
import {
	BellOutlined,
	EnvironmentOutlined,
	StarOutlined,
	UserOutlined,
	ShoppingOutlined,
	WalletOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./Home.css";
import Wishlist from "./Wishlist";
import Profile from "./Profile";
import OrdersTab from "./OrdersTab";
import AddressTab from "./AddressTab";
import Main from "../user/Layout";
import Wallet from "../user/Wallet";
import Notifications from "./Notifications";

const MainHome = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const activeTabFromParams = searchParams.get("tab") || "orders";
	const [activeTab, setActiveTab] = useState(activeTabFromParams);
	const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

	useEffect(() => {
		const activeTabFromParam = searchParams.get("tab");
		if (activeTabFromParam) {
			setSearchParams({ tab: activeTabFromParam });
			setActiveTab(activeTabFromParam);
		} else {
			setSearchParams({ tab: activeTab });
		}
	}, [activeTab,searchParams]);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
		setSearchParams({ tab: tab });
		navigate(`?tab=${tab}`);
		if (isMobileMenuVisible) {
			setMobileMenuVisible(false);
		}
	};

	const handleBackClick = () => {
		setMobileMenuVisible(true);
	};

	return (
		<Main>
			<div className="profile-main-container">
				
				{isMobileMenuVisible ? (
					<div className="mobile-menu">
						<div className="mobile-header">
							<ArrowLeftOutlined
								onClick={() => navigate("/")}
								className="back-icon"
							/>
							<span className="mobile-tab-title">My Account</span>
						</div>
						<ul className="profile-custom-tabs-mobile">
							<li
								className={`profile-tab-mobile ${
									activeTab === "orders" ? "active" : ""
								}`}
								onClick={() => handleTabClick("orders")}>
								<ShoppingOutlined className="profile-tab-icon" />
								My Orders
							</li>

							<li
								className={`profile-tab-mobile ${
									activeTab === "notifications" ? "active" : ""
								}`}
								onClick={() => handleTabClick("notifications")}>
								<BellOutlined className="profile-tab-icon" />
								Notifications
							</li>

							<li
								className={`profile-tab-mobile ${
									activeTab === "address" ? "active" : ""
								}`}
								onClick={() => handleTabClick("address")}>
								<EnvironmentOutlined className="profile-tab-icon" />
								Address
							</li>

							<li
								className={`profile-tab-mobile ${
									activeTab === "wishlist" ? "active" : ""
								}`}
								onClick={() => handleTabClick("wishlist")}>
								<StarOutlined className="profile-tab-icon" />
								Wishlist
							</li>

							<li
								className={`profile-tab-mobile ${
									activeTab === "wallet" ? "active" : ""
								}`}
								onClick={() => handleTabClick("wallet")}>
								<WalletOutlined className="profile-tab-icon" />
								Wallet
							</li>
							<li
								className={`profile-tab-mobile ${
									activeTab === "profile" ? "active" : ""
								}`}
								onClick={() => handleTabClick("profile")}>
								<UserOutlined className="profile-tab-icon" />
								Profile
							</li>
						</ul>
					</div>
				) : (
					<div className="mobile-content-view">
						<div className="mobile-header">
							<ArrowLeftOutlined
								onClick={handleBackClick}
								className="back-icon"
							/>
							<span className="mobile-tab-title">
								{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
							</span>
						</div>
						<div className="profile-tab-content">
							{activeTab === "orders" && <OrdersTab />}
							{activeTab === "notifications" && <Notifications />}
							{activeTab === "address" && <AddressTab />}
							{activeTab === "wishlist" && <Wishlist />}
							{activeTab === "profile" && <Profile />}
							{activeTab === "wallet" && <Wallet />}
						</div>
					</div>
				)}

				<div className="profile-custom-tabs-desktop">
					<button
						className={`profile-tab ${activeTab === "orders" ? "active" : ""}`}
						onClick={() => handleTabClick("orders")}>
						<ShoppingOutlined className="profile-tab-icon" />
						My Orders
					</button>

					<button
						className={`profile-tab ${
							activeTab === "notifications" ? "active" : ""
						}`}
						onClick={() => handleTabClick("notifications")}>
						<BellOutlined className="profile-tab-icon" />
						Notifications
					</button>

					<button
						className={`profile-tab ${activeTab === "address" ? "active" : ""}`}
						onClick={() => handleTabClick("address")}>
						<EnvironmentOutlined className="profile-tab-icon" />
						Address
					</button>

					<button
						className={`profile-tab ${
							activeTab === "wishlist" ? "active" : ""
						}`}
						onClick={() => handleTabClick("wishlist")}>
						<StarOutlined className="profile-tab-icon" />
						Wishlist
					</button>

					<button
						className={`profile-tab ${activeTab === "wallet" ? "active" : ""}`}
						onClick={() => handleTabClick("wallet")}>
						<WalletOutlined className="profile-tab-icon" />
						Wallet
					</button>
					<button
						className={`profile-tab ${activeTab === "profile" ? "active" : ""}`}
						onClick={() => handleTabClick("profile")}>
						<UserOutlined className="profile-tab-icon" />
						Profile
					</button>
				</div>

				<div className="profile-tab-content">
					{activeTab === "orders" && <OrdersTab />}
					{activeTab === "notifications" && <Notifications />}
					{activeTab === "address" && <AddressTab />}
					{activeTab === "wishlist" && <Wishlist />}
					{activeTab === "profile" && <Profile />}
					{activeTab === "wallet" && <Wallet />}
				</div>
			</div>
		</Main>
	);
};

export default MainHome;
